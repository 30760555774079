<template>
  <transition name="fade">
    <div
      v-if="visible"
      :class="{ small }"
      class="modal-pip"
    >
      <div
        class="modal-pip__content"
      >
        <div
          class="modal-pip-close"
          @click="hide"
        >
          <theme-icon
            color="currentColor"
            image="close"
          />
        </div>

        <div
          :class="[ 'modal-pip-resize', { 'increase': small }]"
          @click="minimize"
        >
          <theme-icon
            v-if="!small"
            color="currentColor"
            image="resize"
          />
          <theme-icon
            v-else
            color="white"
            image="resize-increase"
          />
        </div>

        <iframe
          ref="iframe"
          allow="camera;microphone"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import modalPIP from '@/plugins/modalPIP';

export default {
  name: 'ModalPIP',

  data: () => ({
    visible: false,
    small: false,
  }),

  beforeMount() {
    modalPIP.EventBus.$on('show', params => this.show(params));
  },

  methods: {

    hide() {
      this.visible = false;
      this.small = false;
      this.allowBodyScroll();
      this.$nextTick(() => this.$refs.iframe && (this.$refs.iframe.src = undefined));
    },

    confirm() {
    },

    minimize() {
      if (this.small) {
        this.small = false;
        this.preventBodyScroll();
      } else {
        this.small = true;
        this.allowBodyScroll();
      }
    },

    show(params = {}) {
      this.visible = true;
      this.small = false;
      this.preventBodyScroll();
      if (params.iFrameSrc) {
        this.$nextTick(() => this.$refs.iframe && (this.$refs.iframe.src = params.iFrameSrc));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/components/common/modalpip/modalpip';

// themes
@import 'src/components/common/modalpip/audi';
</style>
