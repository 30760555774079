import _ from 'lodash/fp';

const RULE_PATTERN_MAP = {
  minimumLength: '^.{###,}$',
  requireLowercase: '.*[a-z]',
  requireNumbers: '.*\\d',
  requireSymbols: '.*\\W',
  requireUppercase: '.*[A-Z]',
};

export default {

  data: () => ({
    passwordPolicies: {},
  }),

  computed: {
    loginTexts() {
      return this.getProp('login', 'translations[0]', {});
    },

    passwordRules() {
      return _.flow([
        Object.entries,
        _.filter(([key, value]) => key && !!value),
        _.map(item => `${this.getRuleTranslation(item[0])}${item[1] === true ? '' : ` ${item[1]}`}`),
      ])(this.passwordPolicies)
      .join(', ');
    },
  },

  async beforeMount() {
    this.passwordPolicies = await this.$store.dispatch('auth/getPasswordPolicies');
  },

  methods: {
    passwordRulesNotMatched(password) {
      const invalid = Object.keys(this.passwordPolicies).filter(key => {
        const value = this.passwordPolicies[key];
        const regex = new RegExp(RULE_PATTERN_MAP[key].replace('###', value));
        return this.passwordPolicies[key] && !password.match(regex);
      });
      if (!invalid.length) return null;

      return invalid.map(rule => `
        ${rule}
        ${ parseInt(this.passwordPolicies[rule], 10) ? this.passwordPolicies[rule] : '' }
      `);
    },

    getRuleTranslation(key) {
      return this.getText(`password_rule_text_${key.toLowerCase()}`, 'login')
        || key;
    },
  },
};
