// to match cms phases landing modes
const LandingModes = Object.freeze({
  LANDING_MODE_AUTH: 'login',
  LANDING_MODE_REGISTRATION: 'registration',
  LANDING_MODE_COUNTDOWN: 'countdown',
  LANDING_MODE_NONE: 'none',
});

const LandingRoutes = Object.freeze({
  [LandingModes.LANDING_MODE_AUTH]: '/login',
  [LandingModes.LANDING_MODE_COUNTDOWN]: '/countdown',
  [LandingModes.LANDING_MODE_REGISTRATION]: '/register',
  [LandingModes.LANDING_MODE_NONE]: '/',
});

export { LandingModes, LandingRoutes };
