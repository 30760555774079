import cssVars from 'css-vars-ponyfill';

// CSS Vars
import themeDefault from '@/themes/default';
import themeAudi from '@/themes/audi';
import themeAva from '@/themes/ava';
import themeBmwGroup from '@/themes/bmwGroup';
import themeBmwM from '@/themes/bmwM';
import themeBmwI from '@/themes/bmwI';
import themeBmwCar from '@/themes/bmwCar';
import themeBmwMotorrad from '@/themes/bmwMotorrad';
import themeBmwMini from '@/themes/bmwMini';
import themeClassic from '@/themes/classic';
import themeCorporate from '@/themes/corporate';
import themeCorvette from '@/themes/corvette';
import themeHyuStandard from '@/themes/hyuStandard';
import themeIqos from '@/themes/iqos';
import themeLuxury from '@/themes/luxury';
import themeModern from '@/themes/modern';
import themeVW from '@/themes/vw';

import audiLogo from '@/assets/themes/audi/logos/logo.svg?linked';
import audiFavicon from '@/assets/themes/audi/logos/favicon.png';
import avaLogo from '@/assets/themes/ava/logos/dark.svg?linked';
import avaLogoInverted from '@/assets/themes/ava/logos/light.svg?linked';
import avaFavicon from '@/assets/themes/ava/logos/favicon.png';
import avaFooter from '@/assets/themes/ava/logos/footer@3x.png';
import bmwRight from '@/assets/themes/bmw-car/logos/BMW_White-Colour_RGB.svg?linked';
import bmwMotorRadRight from '@/assets/themes/bmw-motorrad/logos/motorrad-logo-de.svg?linked';
import bmwMotorRadInverted from '@/assets/themes/bmw-motorrad/logos/inverted.svg?linked';
import bmwIRight from '@/assets/themes/bmw-i/logos/bmw-i-pairing-white.svg?linked';
import bmwMRight from '@/assets/themes/bmw-m/logos/bmw-m-pairing-white.svg?linked';
import bmwFavicon from '@/assets/themes/bmw-car/logos/bmw_favicon.ico';
import corporateLeft from '@/assets/themes/corporate/logos/logo_left.png';
import corvetteLeft from '@/assets/themes/corvette/logos/logo_left.png';
import corvetteFavicon from '@/assets/themes/corvette/logos/favicon.ico';
import defaultLeft from '@/assets/img/logos/logo_left.png';
import defaultRight from '@/assets/img/logos/logo_right.png';
import defaultFavicon from '@/assets/img/logos/favicon.png';
import groupLeft from '@/assets/themes/bmw-group/logos/logo_left.png';
import groupRight from '@/assets/themes/bmw-group/logos/logo_right.png';
import groupFavicon from '@/assets/themes/bmw-group/logos/favicon.png';
import hyuStandardRight from '@/assets/themes/hyu-standard/logos/logo_right.png';
import hyuFavicon from '@/assets/themes/hyu-standard/logos/favicon.png';
import iqosLogo from '@/assets/themes/iqos/logos/logo.svg?linked';
import iqosFavicon from '@/assets/themes/iqos/logos/favicon.ico';
import mFavicon from '@/assets/themes/bmw-m/logos/favicon.png';
import miniLeft from '@/assets/themes/bmw-mini/logos/logo_left.png';
import miniLeftInverted from '@/assets/themes/bmw-mini/logos/logo_left_inverted.svg?linked';
import miniFavicon from '@/assets/themes/bmw-mini/logos/favicon.png';
import vwLogo from '@/assets/themes/vw/logos/logo.svg?linked';
import vwFavicon from '@/assets/themes/vw/logos/favicon.png';

const CUSTOMERS = [
  {
    name: 'default',
    skin: 'default',
    theme: themeDefault,
    anon: false, // use anonymized styles
    logos: {
      left: defaultLeft,
      right: defaultRight,
      favicon: defaultFavicon,
    },
  },
  {
    name: 'audi',
    skin: 'audi',
    theme: themeAudi,
    anon: false,
    logos: {
      left: audiLogo,
      right: audiLogo,
      favicon: audiFavicon,
    },
  },
  {
    name: 'ava',
    skin: 'ava',
    theme: themeAva,
    anon: false,
    logos: {
      left: avaLogo,
      inverted: avaLogoInverted,
      favicon: avaFavicon,
      footer: avaFooter,
    },
  },
  {
    name: 'bmw-group',
    skin: 'bmw-group',
    theme: themeBmwGroup,
    anon: false,
    logos: {
      left: groupLeft,
      right: groupRight,
      favicon: groupFavicon,
    },
  },
  {
    name: 'bmw-m',
    skin: 'bmw-m',
    theme: themeBmwM,
    anon: false,
    logos: {
      right: bmwMRight,
      favicon: mFavicon,
    },
  },
  {
    name: 'bmw-car',
    skin: 'bmw-car',
    theme: themeBmwCar,
    anon: false,
    logos: {
      right: bmwRight,
      favicon: bmwFavicon,
    },
  },
  {
    name: 'bmw-i',
    skin: 'bmw-i',
    theme: themeBmwI,
    anon: false,
    logos: {
      right: bmwIRight,
      favicon: bmwFavicon,
    },
  },
  {
    name: 'bmw-motorrad',
    skin: 'bmw-motorrad',
    theme: themeBmwMotorrad,
    anon: false,
    logos: {
      right: bmwMotorRadRight,
      inverted: bmwMotorRadInverted,
      footer: bmwMotorRadInverted,
      favicon: bmwFavicon,
    },
  },
  {
    name: 'bmw-mini',
    skin: 'bmw-mini',
    theme: themeBmwMini,
    anon: false,
    logos: {
      left: miniLeft,
      inverted: miniLeftInverted,
      footer: miniLeftInverted,
      favicon: miniFavicon,
    },
  },
  {
    name: 'classic',
    skin: 'bmw-mini',
    theme: themeClassic,
    anon: true,
    logos: {
      left: defaultLeft,
      inverted: defaultRight,
      footer: defaultRight,
      favicon: defaultFavicon,
    },
  },
  {
    name: 'corporate',
    skin: 'corporate',
    theme: themeCorporate,
    anon: false,
    logos: {
      left: defaultLeft,
      favicon: corporateLeft,
    },
  },
  {
    name: 'corvette',
    skin: 'corvette',
    theme: themeCorvette,
    anon: false,
    logos: {
      left: corvetteLeft,
      footer: corvetteLeft,
      favicon: corvetteFavicon,
    },
  },
  {
    name: 'hyu-standard',
    skin: 'hyu-standard',
    theme: themeHyuStandard,
    anon: false,
    logos: {
      right: hyuStandardRight,
      favicon: hyuFavicon,
    },
  },
  {
    name: 'iqos',
    skin: 'iqos',
    theme: themeIqos,
    anon: false,
    logos: {
      left: iqosLogo,
      favicon: iqosFavicon,
    },
  },
  {
    name: 'luxury',
    skin: 'bmw-car',
    theme: themeLuxury,
    anon: true,
    logos: {
      left: defaultLeft,
      right: defaultRight,
      favicon: defaultFavicon,
    },
  },
  {
    name: 'modern',
    skin: 'hyu-standard',
    theme: themeModern,
    anon: true,
    logos: {
      left: defaultLeft,
      right: defaultRight,
      favicon: defaultFavicon,
    },
  },
  {
    name: 'vw',
    skin: 'vw',
    theme: themeVW,
    anon: false,
    logos: {
      left: vwLogo,
      right: vwLogo,
      favicon: vwFavicon,
    },
  },
];

const DEFAULT_CUSTOMER = {
  name: 'default',
  skin: 'default',
  theme: themeDefault,
  header: 'Default Theme',
};

/* changes theme based on themeName. If no themeName provided check domainname */
export const changeCustomer = (themeName) => {
  const [theme] = CUSTOMERS.filter(({ name }) => name === themeName);
  const newTheme = theme || DEFAULT_CUSTOMER;
  cssVars({ variables: newTheme.theme });
  return newTheme;
};

export default changeCustomer;
