export default {
  'app-container-width': 'none',

  'body-base-color': '#333',
  'body-background-color': '#333',

  'font-family--header': '"Audi Type Extended Normal", sans-serif',
  'font-family--interactive': '"Audi Type Wide Normal", sans-serif',
  'font-family--text-link': '"Audi Type Wide Normal", sans-serif',
  'font-family--bold': '"Audi Type Extended Normal", sans-serif',
  'font-family--body': '"Audi Type Wide Normal", sans-serif',
  'font-family--light': '"Audi Type Extended Normal", sans-serif',

  'header-text-transform': 'none',

  'card-background-color': '#ffffff',
  'card-font-color': '#333',

  'header-background-color': '#fff',
  'header-font-color': '#000',
  'header-icon-display': 'block',

  'contact-card-color': '#262626',
  'contact-card-background': '#f6f6f6',

  'page-header-height': '140px',
  'page-header-height--medium': '340px',
  'page-header-background-color': '#262626',
  'page-header-color': '#fff',

  'home-tile-border-color': '#fff',
  'home-tile-border-width': '0.125rem',
  'home-tile-icons-visibility': 'visible',
  'home-tile-height': '135px',
  'home-tile-height--medium': '240px',

  'app-button-border-width': '1px', // keep the 'px' unit for calculations

  'app-button-bg-color--hover': 'transparent',
  'app-button-bg-color': 'transparent',
  'app-button-border-color--hover': '#4c4c4c',
  'app-button-border-color': '#000',
  'app-button-color--hover': '#4c4c4c',
  'app-button-color': '#000',
  'app-button-disabled-bg-color': 'transparent',
  'app-button-disabled-color': '#b3b3b3',
  'app-button-font-size': '16px',
  'app-button-line-height': '1.25',

  'app-button--primary-bg-color': '#000000',
  'app-button--primary-bg-color--hover': '#4c4c4c',
  'app-button--primary-border-color': '#000000',
  'app-button--primary-border-color--hover': '#4c4c4c',
  'app-button--primary-color': '#fff',
  'app-button--primary-color--hover': '#fff',
  'app-button--primary-disabled-bg-color': '#b3b3b3',
  'app-button--primary-disabled-color': '#fff',

  'app-button--primary-invert-bg-color': '#fff',
  'app-button--primary-invert-bg-color--hover': '#4c4c4c',
  'app-button--primary-invert-border-color': '#fff',
  'app-button--primary-invert-border-color--hover': '#4c4c4c',
  'app-button--primary-invert-color': '#000000',
  'app-button--primary-invert-color--hover': '#fff',
  'app-button--primary-invert-disabled-bg-color': '#b3b3b3',
  'app-button--primary-invert-disabled-color': '#fff',

  'app-button--secondary-bg-color': 'transparent',
  'app-button--secondary-bg-color--hover': 'transparent',
  'app-button--secondary-border-color': '#000',
  'app-button--secondary-border-color--hover': '#4c4c4c',
  'app-button--secondary-color': '#000',
  'app-button--secondary-color--hover': '#4c4c4c',
  'app-button--secondary-disabled-bg-color': 'transparent',
  'app-button--secondary-disabled-color': '#b3b3b3',

  'app-button--secondary-invert-bg-color': 'transparent',
  'app-button--secondary-invert-bg-color--hover': 'transparent',
  'app-button--secondary-invert-border-color': '#fff',
  'app-button--secondary-invert-border-color--hover': '#d1c3c4',
  'app-button--secondary-invert-color': '#fff',
  'app-button--secondary-invert-color--hover': '#d1c3c4',
  'app-button--secondary-invert-disabled-bg-color': 'transparent',
  'app-button--secondary-invert-disabled-color': '#b3b3b3',

  'app-button--outline-bg-color': 'transparent',
  'app-button--outline-bg-color--hover': 'transparent',
  'app-button--outline-border-color': '#000',
  'app-button--outline-border-color--hover': '#4c4c4c',
  'app-button--outline-border-width': '1px',
  'app-button--outline-color': '#000',
  'app-button--outline-color--hover': '#4c4c4c',
  'app-button--outline-disabled-bg-color': 'transparent',
  'app-button--outline-disabled-border-color': '#b3b3b3',
  'app-button--outline-disabled-color': '#b3b3b3',

  'app-button-align': 'center',
  'app-button-color-squared': '#262626',
  'app-button-min-height': '3.75em',
  'app-button-min-width': '9em',
  'app-button-min-width--medium': '9em',
  'app-button-padding': '1em 2em',
  'app-button-text-transform': 'none',

  'header-height': '60px',
  'footer-height': '120px',
  'header-height--medium': '60px',
  'footer-height--medium': '80px',

  'app-page-footer-background-color': '#000',
  'app-page-footer-link-color': '#fff',
  'app-page-footer-color': '#000',

  'link-color': '#333333',

  'body-base-font-size': '16px',
  'body-base-font-size--medium': '16px',
  'body-base-line-height': '1.5',
  'body-base-line-height--medium': '1.5',

  'rating-inactive': '#fff',
  'rating-active': '#000',

  'input-font-size': '16px',
  'input-background': 'transparent',
  'input-height': '40px',
  'input-color': '#0a0a0a',
  'input-border-color': 'rgba(0,0,0,0.3)',
  'input-border-color--focus': 'rgba(0,0,0,0.3)',
  'input-placeholder-color': '#bbbbbb',
  'input-placeholder-text-transform': 'none',
  'input-padding': '0.5rem 0',
  'select-option-color': '#fff',
  'select-option-color--hover': '#ffffff',
  'select-option-background': '#333',
  'select-option-background--hover': '#4d4d4d',

  'compendium-card-color': '#fff',
  'compendium-card-background': '#666',
  'compendium-card-icon-color': '#fff',
  'compendium-card-button-color': '#fff',
  'compendium-card-button-background': '#666',
  'compendium-card-border-width': '0px',
  'compendium-card-border-color': 'transparent',

  'compendium-card-color--hover': '#fff',
  'compendium-card-background--hover': '#666',
  'compendium-card-icon-color--hover': '#fff',
  'compendium-card-button-color--hover': '#fff',
  'compendium-card-button-background--hover': '#666',
  'compendium-card-border-width--hover': '0px',
  'compendium-card-border-color--hover': 'transparent',

  'compendium-card-color-primary': '#fff',
  'compendium-card-background-primary': '#000',
  'compendium-card-icon-color-primary': '#fff',
  'compendium-card-button-color-primary': '#fff',
  'compendium-card-button-background-primary': '#000',

  'compendium-card-color-primary--hover': '#000',
  'compendium-card-background-primary--hover': '#fff',
  'compendium-card-icon-color-primary--hover': '#000',
  'compendium-card-button-color-primary--hover': '#000',
  'compendium-card-button-background-primary--hover': '#fff',
};
