export default containerIds => {
  containerIds.map(el => {
    // eslint-disable-next-line
    const _mtm = window._mtm = window._mtm || [];
    _mtm.push({ 'mtm.startTime': (new Date().getTime()), event: 'mtm.Start' });
    const d = document;
    const g = d.createElement('script');
    const s = d.getElementsByTagName('script')[0];
    g.type = 'text/javascript';
    g.async = true;
    g.src = `${el.container_id}`;
    s.parentNode.insertBefore(g, s);
    return el;
  });
};
