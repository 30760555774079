<template>
  <landing-layout
    :heading="getText('request_password_reset_welcome', textCollection)"
    :copy="getText('request_password_reset_copy', textCollection)"
    class="request-password-reset"
  >
    <div class="landinglayout__container">
      <div class="landinglayout__form">
        <form
          v-if="!requestPasswordResetSuccess"
          @submit.prevent="requestPassword"
        >
          <app-input
            v-if="!autoRequest"
            ref="input"
            v-model="email"
            :input-class="['landinglayout__input', { 'error': validationErrors.email }]"
            :label="getText('request_password_email_placeholder', textCollection)"
            :placeholder="getText('request_password_email_placeholder', textCollection)"
            type="text"
            autocorrect="off"
            autocapitalize="none"
            autocomplete="email"
            @input="validationErrors.email = null"
            @keyup.enter="requestPassword"
          />

          <div class="landinglayout__errors">
            <div
              v-if="serverSideError"
              class="landinglayout__error-message"
            >
              {{ serverSideError }}
            </div>
            <div
              v-for="(error, idx) in Object.values(validationErrors).filter(e => !!e && e.trim())"
              :key="idx"
              class="landinglayout__error-message"
            >
              {{ error }}
            </div>
          </div>

          <app-button
            v-if="!autoRequest"
            :disabled="loading"
            :label="getText('request_password_reset_submit_label', textCollection)"
            class="landinglayout__button"
          />
        </form>
        <div
          v-else
          class="landinglayout__success-message"
        >
          {{ getText('request_password_reset_success_message', textCollection) }}
        </div>

        <div class="landinglayout__links">
          <router-link
            :to="{ name: 'Login' }"
            class="landinglayout__link"
          >
            {{ getText('request_password_back_to_login_link_label', textCollection) }}
          </router-link>
        </div>
      </div>
    </div>
  </landing-layout>
</template>

<script>
// Constants
import { ConfigurationErrors } from '@/util/errors';

import LandingLayout from '@/layouts/landinglayout/LandingLayout';

export default {
  name: 'RequestPasswortReset',

  components: {
    LandingLayout,
  },

  data: () => ({
    autoRequest: false,
    loading: false,
    requestPasswordResetSuccess: false,
    requestPasswordResetSuccessMessage: '',
    email: '',
    validationErrors: {},
    serverSideError: false,
    textCollection: 'login',
  }),

  computed: {
    loginTexts() {
      return this.getProp('login', 'translations[0]', {});
    },
  },

  async mounted() {
    if (!this.$route.query.email) return;
    this.autoRequest = true;
    this.email = this.$route.query.email;

    await this.$store.dispatch('cms/getItems', { tableName: 'login', params: { fields: '*.*.*' } });
    this.requestPassword();
  },

  methods: {
    validate() {
      this.validationErrors = {};
      this.serverSideError = false;

      if (!this.email) {
        this.validationErrors.email = this.getText('request_password_reset_email_missing_error_message', this.textCollection) || ' ';
      } else if (!this.validEmail(this.email)) {
        this.validationErrors.email = this.getText('request_password_reset_email_invalid_error_message', this.textCollection) || ' ';
      }

      return !Object.values(this.validationErrors).length;
    },

    async requestPassword() {
      if (!this.validate()) return;
      if (this.$refs.input) this.$refs.input.$refs.input.blur();
      this.loading = true;

      try {
        await this.$store.dispatch('auth/userservice/requestPasswordReset', { email: this.email });
        this.requestPasswordResetSuccessMessage = this.getText('request_password_reset_success_message', this.textCollection);
        this.requestPasswordResetSuccess = true;
      } catch (err) {
        switch (err.message) {
          case ConfigurationErrors.OPERATION_NOT_SUPPORTED:
            this.serverSideError = this.getText('config_not_supported');
            break;
          default: this.serverSideError = this.getText('request_password_reset_generic_error', this.textCollection);
        }
      } finally {
        this.loading = false;
      }

      this.$tracking.event({
        eventCategory: 'User',
        eventAction: this.passwordRequestResetSuccess ? 'RequestPasswordReset' : 'RequestPasswordResetFailed',
      });
    },
  },
};
</script>
