import sha224 from 'crypto-js/sha224';
import store from '@/store';

import { ApiErrors } from '@/util/errors';

const { VUE_APP_CMS_USER, VUE_APP_LOGIN_TYPE } = process.env;

const log = (txt, params) => store.state.debug && console.log(`Auth Module: ${txt}`, params);

export default {
  namespaced: true,

  state: {
    user: null,
    loginMode: VUE_APP_LOGIN_TYPE,
  },

  mutations: {
    setUser: (state, user) => state.user = user,
  },

  getters: {
    authenticated: (state, _, rootState) => !!state.user && rootState.cms.authenticated,
  },

  actions: {
    /**
     * - Calls init action of active auth module
     *   use cases:
     *     - do pre-checks if service is available / working correctly
     *
     * - Triggers user action
     * - Triggers CMS Token refresh (handled by ir-vuex-directus)
     *   can fail if not yet authenticated with service / cms
     *
     * @returns {Promise<void>}
     */
    async init({ dispatch, state, rootState }) {
      log(`init ${state.loginMode}`);

      try {
        if (rootState.cms.authenticated) {
          await dispatch('cms/refresh', {}, { root: true });
        }
      } catch (err) {
        // throw error just in case the services are not available
        if (!err.response || err?.response?.status === 404) throw new Error('CMS not available');
      }

      try {
        await dispatch('user');
      } catch (err) {
        // throw error just in case the services are not available
        if (!err.response || err?.response?.status === 404) throw new Error(`${state.loginMode} auth not available`);
      }

      await dispatch('dispatchLoginModuleAction', { action: 'init' });
    },

    /**
     * Calls login action of active auth module
     *
     * @param { string } returnToRoute url to return to after login
     * @param {{ email:string, password:string }} credentials email, password object
     *
     * @returns {Promise<void>}
     */
    login({ dispatch, state }, { returnToRoute, credentials }) {
      log(`login ${state.loginMode}`);
      return dispatch('dispatchLoginModuleAction', { action: 'login', returnToRoute, credentials });
    },

    /**
     * - Triggers ir-vuex-directus module logout
     * - Cleans up local storage from directus tokens and vuex store
     * - Calls logout action of active auth module
     *
     * @param { string } returnToRoute url to return to after logout
     *
     * @returns {Promise<void>}
     */
    async logout({ dispatch, state }, { returnToRoute }) {
      log(`logout ${state.loginMode}`);
      await dispatch('cms/logout', {}, { root: true })
        .catch(() => {
          // nothing, logout fails if refresh token is missing
        })
        // directus sdk removes tokens after promise is resolved
        // but we need to make sure they are removed before auth module logout action
        .finally(() => {
          [
            'auth_token',
            'auth_expires_at',
            'auth_expires',
            'refresh_token',
            'vuex',
            'ScheduleHash',
            'InformationHash',
          ].forEach(key => localStorage.removeItem(key));
          // Removing this key prompts users to accept cookies again
          // localStorage.removeItem('project_id');
        });
        return dispatch('dispatchLoginModuleAction', { action: 'logout', returnToRoute });
    },

    /**
     * Calls user action of active auth module
     *
     * @returns {Promise<void>}
     */
    async user({ commit, dispatch, state }) {
      log(`get user ${state.loginMode}`);
      const userData = await dispatch('dispatchLoginModuleAction', { action: 'user' });
      commit('setUser', userData);
    },

    /**
     * Calls password Policies action of active auth module
     *
     * @returns {Promise<void>}
     */
    async getPasswordPolicies({ dispatch, state }) {
      log(`get user ${state.loginMode}`);
      return dispatch('dispatchLoginModuleAction', { action: 'getPasswordPolicies' });
    },

    /**
     * Calls login action of ir-vuex-directus module
     *
     * @param { string } group password of the directus web user
     *
     * @returns {Promise<void>}
     */
    async directusLogin({ dispatch }, { group }) {
      log('directus login', group);
      // CMS login with user: sha224(password)@VUE_APP_CMS_USER / password
      const cmsSuccess = await dispatch('cms/login', {
        email: `${sha224(group)}${VUE_APP_CMS_USER}`,
        password: group,
      }, { root: true });

      if (!cmsSuccess) throw new Error(ApiErrors.CMS_LOGIN_FAILED);
    },

    dispatchLoginModuleAction({ state, dispatch }, args) {
      const moduleAction = `${state.loginMode}/${args.action}`;
      // eslint-disable-next-line
      if (!this._actions[`auth/${moduleAction}`]) return true;
      return dispatch(moduleAction, args);
    },
  },
};
