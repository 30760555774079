<template>
  <div
    class="description-list"
  >
    <div class="description-list__headline">
      <div class="description-list__block">
        <template v-if="headline">
          <h3 class="description-list__limited">
            {{ headline }}
          </h3>
        </template>
      </div>
      <div class="description-list__block">
        <slot name="left" />
      </div>
    </div>

    <div
      v-if="showSlot"
      class="description-list__slot"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DescriptionList',
  props: {
    headline: {
      type: String,
      default: () => '',
    },
    showSlot: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/abstracts/_mixins';
@import 'src/assets/scss/abstracts/_variables';

@import 'src/components/common/descriptionlist/descriptionlist';

// Themes
@import 'src/components/common/descriptionlist/audi';
@import 'src/components/common/descriptionlist/ava';
@import 'src/components/common/descriptionlist/bmw-car';
@import 'src/components/common/descriptionlist/bmw-group';
@import 'src/components/common/descriptionlist/bmw-i';
@import 'src/components/common/descriptionlist/bmw-m';
@import 'src/components/common/descriptionlist/bmw-mini';
@import 'src/components/common/descriptionlist/bmw-motorrad';
@import 'src/components/common/descriptionlist/corporate';
@import 'src/components/common/descriptionlist/corvette';
@import 'src/components/common/descriptionlist/hyu-standard';
</style>
