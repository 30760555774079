<template>
  <component
    :is="svgComponent"
    :fill="color"
    :style="{fill: color}"
    :class="`${iconClass} ${image}`"
  />
</template>

<script>
import skinner from '@/components/mixins/skinner';
import icons from '@/helper/themeIconMapper';

export default {
  mixins: [skinner],
  props: {
    image: {
      type: String,
      default: 'info',
    },
    color: {
      type: String,
      default: 'currentColor',
    },
    iconClass: {
      type: String,
      default: () => 'theme-icon',
    },
  },
  computed: {
    svgComponent() {
      try {
        const iconName = `${(icons[this.getSkin] || {})[this.image] || this.image}`;
        // eslint-disable-next-line
        return require(`@/assets/themes/${this.getSkin}/icons/${iconName}.svg`);
      } catch (e) {
        try {
          const iconName = `${(icons.default || {})[this.image] || this.image}`;
          // eslint-disable-next-line
          return require(`@/assets/img/icons/${iconName}.svg`);
        } catch {
          return null;
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/abstracts/_mixins';
@import 'src/assets/scss/abstracts/_variables';

@import 'src/components/common/themeicon/themeicon';
</style>
