var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.iconSquared)?_c(_vm.href ? 'a' : 'button',{tag:"component",staticClass:"app-button",class:{ 'app-button--icon-only': !_vm.label,
            'app-button--full': _vm.full,
            'app-button--icon': _vm.icon && _vm.label,
            'app-button--internal': _vm.internal,
            'app-button--primary': _vm.primary,
            'app-button--outline': _vm.outline,
            'app-button--secondary': _vm.secondary,
            'invert': _vm.invert },attrs:{"href":_vm.href,"target":_vm.target,"disabled":_vm.disabled},on:{"click":_vm.clickHandler}},[_c('theme-icon',{class:['icon-svg', _vm.iconClass],attrs:{"image":_vm.buttonIcon,"color":_vm.iconColor}}),(_vm.label)?_c('span',{class:_vm.labelClass},[_c('span',{directives:[{name:"sane-html",rawName:"v-sane-html",value:(_vm.label),expression:"label"}],staticClass:"app-button__label-inner"})]):_vm._e()],1):_c(_vm.href ? 'a' : 'button',{tag:"component",staticClass:"app-button-icon-squared__wrapper",attrs:{"href":_vm.href,"target":_vm.target,"disabled":_vm.disabled},on:{"click":_vm.clickHandler}},[_c('div',{staticClass:"app-button app-button--icon-only",class:{ 'app-button--primary': _vm.primary,
              'app-button--secondary': _vm.secondary,
            }},[_c('theme-icon',{class:['icon-svg', _vm.iconClass],attrs:{"image":_vm.buttonIcon,"color":_vm.iconColor}})],1),_c('span',{directives:[{name:"sane-html",rawName:"v-sane-html",value:(_vm.label),expression:"label"}],staticClass:"app-button__label"})])
}
var staticRenderFns = []

export { render, staticRenderFns }