<template>
  <landing-layout
    :heading="getText('welcome', textCollection)"
    :copy="getText('copy', textCollection)"
    class="registration"
  >
    <div class="landinglayout__container">
      <div class="landinglayout__form">

        <form
          v-if="!registrationSuccess"
          @submit.prevent="register"
        >
          <div class="landinglayout__errors">
            <div
              v-if="serverSideError"
              class="landinglayout__error-message"
            >
              {{ serverSideError }}
            </div>
            <div
              v-for="(error, idx) in Object.values(validationErrors).filter(e => !!e && e.trim())"
              :key="idx"
              class="landinglayout__error-message"
            >
              <span v-sane-html="error" />
            </div>
          </div>

          <app-select
            v-model="honorific"
            :options="honorificOptions"
          />
          <app-input
            ref="input"
            v-model="firstName"
            :input-class="['landinglayout__input', { 'error': validationErrors.firstName }]"
            :label="getText('first_name_placeholder', textCollection)"
            :placeholder="getText('first_name_placeholder', textCollection)"
            type="text"
            autocorrect="off"
            autocapitalize="none"
            autocomplete="given-name"
            @input="validationErrors.firstName = null"
            @keyup.enter="register"
          />

          <app-input
            ref="input"
            v-model="lastName"
            :input-class="['landinglayout__input', { 'error': validationErrors.lastName }]"
            :label="getText('last_name_placeholder', textCollection)"
            :placeholder="getText('last_name_placeholder', textCollection)"
            type="text"
            autocorrect="off"
            autocapitalize="none"
            autocomplete="family-name"
            @input="validationErrors.lastName = null"
            @keyup.enter="register"
          />

          <app-input
            ref="input"
            v-model="email"
            :input-class="['landinglayout__input', { 'error': validationErrors.email }]"
            :label="getText('email_placeholder', textCollection)"
            :placeholder="getText('email_placeholder', textCollection)"
            type="text"
            autocorrect="off"
            autocapitalize="none"
            autocomplete="email"
            @input="validationErrors.email = null"
            @keyup.enter="register"
          />

          <span class="landinglayout__note">{{ getText('password_note', textCollection) }}</span>

          <app-input
            ref="input"
            v-model="password"
            :input-class="['landinglayout__input', { 'error': validationErrors.password } ]"
            :label="getText('password_placeholder', textCollection)"
            :placeholder="getText('password_placeholder', textCollection)"
            type="password"
            autocorrect="off"
            autocapitalize="none"
            autocomplete="current-password"
            @input="validationErrors.password = null"
            @keyup.enter="register"
          />

          <div v-if="registrationTexts.tc_text">
            <label
              :class="['landinglayout__label', { 'error': validationErrors.tc }]"
              class="landinglayout__checkbox"
            >
              <input
                v-model="tc"
                :class="['landinglayout__input', { 'error': validationErrors.tc }]"
                type="checkbox"
                value="1"
              >
              <div>
                {{ registrationTexts.tc_text }}
                <a
                  :href="registrationTexts.tc_link"
                  target="_blank"
                >
                  {{ registrationTexts.tc_link_label }}
                </a>
              </div>
            </label>
          </div>

          <router-view />

          <app-button
            :disabled="loading"
            :label="getText('submit_label', textCollection)"
            class="landinglayout__button"
          />
        </form>
        <div
          v-else
          class="landinglayout__success-message"
        >
          {{ getText('success_message', textCollection) }}
        </div>
      </div>
    </div>
  </landing-layout>
</template>

<script>
// Constants
import { ApiErrors, ConfigurationErrors } from '@/util/errors';

import LandingLayout from '@/layouts/landinglayout/LandingLayout';
import AppSelect from '@/components/common/select/Select';

import passwordValidator from '@/helper/passwordValidator';

export default {
  name: 'Registration',

  components: {
    LandingLayout,
    AppSelect,
  },

  mixins: [passwordValidator],

  data: () => ({
    loading: false,
    registrationSuccess: false,
    tc: null,
    honorific: null,
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    serverSideError: false,
    validationErrors: {},
    textCollection: 'registration',
  }),

  computed: {
    registrationTexts() {
      return this.getProp('registration', 'translations[0]', {});
    },

    honorificOptions() {
      const options = this.getText('honorific_options', this.textCollection);
      return options instanceof Array ? options.map(item => item.option) : [];
    },
  },

  mounted() {
    this.$store.dispatch('cms/getItems', { tableName: 'registration', params: { fields: '*.*.*' } });
  },

  methods: {
    async register() {
      if (!this.validate()) return;
      this.loading = true;

      try {
        this.$refs.input.$refs.input.blur();
        this.registrationSuccess = false;

        const registrationData = {
          title: this.honorific,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
        };

        await this.$store.dispatch('auth/userservice/register', { ...registrationData });
        this.registrationSuccess = true;
      } catch (err) {
        switch (err.message) {
          case ApiErrors.CONFLICT:
            this.serverSideError = this.getText('user_exists_error_message', this.textCollection);
            break;
          case ApiErrors.LOCKED:
            this.serverSideError = this.getText('event_locked_error_message', this.textCollection);
            break;
          case ConfigurationErrors.OPERATION_NOT_SUPPORTED:
            this.serverSideError = this.getText('config_not_supported');
            break;
          default: this.serverSideError = this.getText('generic_error_message', this.textCollection);
        }
      }

      this.loading = false;
    },

    validate() {
      this.validationErrors = {};
      this.serverSideError = false;

      if (!this.honorific) {
        this.validationErrors.honorific = this.getText('honorific_missing_error_message', this.textCollection) || ' ';
      }
      if (!this.firstName) {
        this.validationErrors.firstName = this.getText('first_name_missing_error_message', this.textCollection) || ' ';
      }
      if (!this.lastName) {
        this.validationErrors.lastName = this.getText('last_name_missing_error_message', this.textCollection) || ' ';
      }
      if (!this.password) {
        this.validationErrors.password = this.getText('password_missing_error_message', this.textCollection) || ' ';
      } else {
        const invalidRules = this.passwordRulesNotMatched(this.password);
        if (invalidRules) {
          this.validationErrors.password = this.getText('password_too_weak_error_message', this.textCollection) || ' ';
          this.validationErrors.password += `<ul><li>${ invalidRules.join('</li><li>') }</li></ul>`;
        }
      }
      if (!this.email) {
        this.validationErrors.email = this.getText('email_missing_error_message', this.textCollection) || ' ';
      } else if (!this.validEmail(this.email)) {
        this.validationErrors.email = this.getText('email_invalid_error_message', this.textCollection) || ' ';
      }
      if (!this.tc) {
        this.validationErrors.tc = this.getText('tc_not_accepted_error_message', this.textCollection) || ' ';
      }

      return !Object.values(this.validationErrors).length;
    },
  },
};
</script>
