export const checkProject = () => {
  // Get project & store in local storage
  const projectEnvId = process.env.VUE_APP_PROJECT_ID || '';
  const projectId = localStorage.getItem('project_id');

  if (projectId !== projectEnvId) {
    localStorage.clear();
    localStorage.setItem('project_id', projectEnvId);
  }

  return localStorage.getItem('project_id');
};

export default checkProject;
