<template>
  <landing-layout
    :heading="getText('registration_confirm_welcome', textCollection)"
    :copy="getText('registration_confirm_copy', textCollection)"
    class="registration-confirm"
  >
    <div class="landinglayout__container">
      <div class="landinglayout__form">
        <div
          v-if="serverSideError"
          class="landinglayout__error-message"
        >
          {{ serverSideError }}
        </div>

        <div
          v-if="!serverSideError && registrationConfirmSuccess"
          class="landinglayout__success-message"
        >
          {{ registrationConfirmSuccessMessage }}
        </div>

        <app-button
          v-if="registrationConfirmSuccess"
          :disabled="loading"
          :callback="() => $router.push({ name: 'Login' })"
          :label="getText('registration_confirm_enter_now_button_label', textCollection)"
          primary
        />
      </div>
    </div>
  </landing-layout>
</template>

<script>
// Constants
import { ConfigurationErrors } from '@/util/errors';

import LandingLayout from '@/layouts/landinglayout/LandingLayout';

export default {
  name: 'PasswortReset',

  components: {
    LandingLayout,
  },

  data: () => ({
    loading: false,
    registrationConfirmSuccess: false,
    registrationConfirmSuccessMessage: '',
    validationErrors: {},
    registrationConfirmError: '',
    serverSideError: false,
    textCollection: 'registration',
  }),

  beforeMount() {
    this.$store.dispatch('cms/getItems', { tableName: 'registration', params: { fields: '*.*.*' } });

    if (!this.$route.query.verificationCode || !this.$route.query.email) this.logout();
    this.confirmRegistration();
  },

  methods: {
    async confirmRegistration() {
      this.loading = true;

      try {
        await this.$store.dispatch('auth/userservice/confirmRegistration', {
          email: this.$route.query.email,
          verificationCode: this.$route.query.verificationCode,
        });
        this.registrationConfirmSuccessMessage = this.getText('registration_confirm_success_message', this.textCollection);
        this.registrationConfirmSuccess = true;
      } catch (err) {
        switch (err.message) {
          case ConfigurationErrors.OPERATION_NOT_SUPPORTED:
            this.serverSideError = this.getText('config_not_supported');
            break;
          default: this.serverSideError = this.getText('registration_confirm_generic_error', this.textCollection);
        }
      } finally {
        this.loading = false;
      }

      this.$tracking.event({
        eventCategory: 'User',
        eventAction: this.registrationConfirmSuccess ? 'registrationConfirm' : 'registrationConfirmFailed',
      });
    },
  },
};
</script>

<style lang="scss">
@import '~foundation-sites/scss/foundation';
@import 'src/assets/scss/abstracts/variables';
@import 'src/assets/scss/abstracts/mixins';
</style>
