<template lang="html">
  <article class="page content-wrapper simple-page">
    <PageHeaderText
      :image="responsiveCmsImage(imageHeader)"
      :header-text="textHeader"
      margin
    />

    <div class="simple-page__container">
      <div
        v-sane-html="page.type === 'page' ? page.content : page.html_code"
        :class="{'simple-page__content--embedded-code': page.type === 'embedded-code' }"
        class="simple-page__content"
      />
    </div>

    <description-list
      v-for="(item, index) in page.content_list"
      :key="index"
      :headline="item.content_heading"
    >
      <div v-sane-html="item.content_text" />

      <cookiebot-declaration v-if="item.show_cookie_declaration" />
    </description-list>
  </article>
</template>

<script>
import { mapState } from 'vuex';
import CookiebotDeclaration from '@/components/common/cookiebot/CookiebotDeclaration';

export default {
  name: 'SimplePage',

  components: {
    CookiebotDeclaration,
  },

  data: () => ({
    page: {},
  }),

  computed: {
    ...mapState('cms', {
      simplePages: state => state.simple_pages || [],
    }),

    textHeader() {
      return this.safeGet(this.page, 'heading');
    },

    imageHeader() {
      return this.safeGet(this.page, 'header_background_image.id');
    },

    path() {
      return this.$route.params.path;
    },
  },

  async beforeMount() {
    if (!this.simplePages.length) {
      await this.$store.dispatch('cms/getItems', { tableName: 'simple_pages', params: { fields: '*.*' } });
    }

    const page = this.simplePages.find(p => p.path === this.path);

    if (!page) this.$router.push({ path: '/' });

    this.page = page || {};
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/abstracts/_mixins';
@import 'src/assets/scss/abstracts/_variables';

@import 'simple-page';

// Themes
@import 'audi';
@import 'ava';
@import 'corvette';
</style>
