<template>
  <div
    :class="{ 'is-focus': focused, 'is-dirty': dirty }"
    class="app-textarea"
  >
    <label
      v-if="label"
      class="app-textarea__label"
    >
      {{ label }}
    </label>
    <textarea
      ref="textarea"
      :class="inputClass"
      :value="value"
      v-bind="$attrs"
      @blur="onBlur"
      @focus="onFocus"
      @input="onInput"
      @change="onChange"
    />
  </div>
</template>

<script>
  export default {
    name: 'AppTextarea',
    inheritAttrs: false,
    props: {
      label: {
        type: String,
        required: false,
        default: '',
      },
      value: {
        type: String,
        required: false,
      },
      inputClass: {
        type: [Array, String],
        required: false,
        default: '',
      },
    },
    data() {
      return {
        focused: false,
        dirty: false,
      };
    },
    mounted() {
      this.dirty = !!(this.$refs.textarea.value || '').trim();
    },
    methods: {
      onBlur() {
        this.focused = false;
      },
      onFocus() {
        this.focused = true;
      },
      onInput(event) {
        this.dirty = !!(event.target.value || '').trim();
        this.$emit('input', event.target.value);
      },
      onChange(event) {
        this.$emit('change', event.target.value);
      },
    },
  };
</script>

<style lang="scss">
  @import 'src/assets/scss/abstracts/_mixins';
  @import 'src/assets/scss/abstracts/_variables';

  @import 'src/components/common/textarea/textarea';

  // Themes
  @import 'src/components/common/textarea/audi';
  @import 'src/components/common/textarea/iqos';
  @import 'src/components/common/textarea/vw';
</style>
