import LoginButton from './components/login-button/LoginButton';

export default [
   {
    parent: 'Login',
    route: {
      path: 'oidc',
      component: LoginButton,
      meta: { type: 'landing', hideNavigation: true },
    },
   },
];
