import moment from 'moment-timezone';
import momentDuration from 'moment';
import fp from 'lodash/fp';
import safeGet from 'lodash/get';
import { mapState, mapGetters } from 'vuex';

import { LandingRoutes } from '@/util/landingModes';
import defaultTexts from '@/util/defaultTexts';

// constants
import { PHASE_CONSTANTS } from '@/store/phasesModule';

export default {
  data: () => ({
    momentDuration,
    moment,
  }),
  computed: {
    ...mapGetters('auth', ['authenticated']),
    ...mapGetters('cms', ['getProp']),
    ...mapState(['cmsBasePath', 'customer']),
    ...mapState({
      group: state => state.selectedGroup,
    }),
    ...mapState('cms', {
      cmsGroup: state => state.group,
      isInternal: state => (state.group || '').includes('internal'),
      texts: ({ texts = {} }) => fp.flow(
        fp.keyBy('key'),
        fp.mapValues(o => (o.translations[0] || {}).text || ''),
      )(texts),
    }),
    ...mapGetters(PHASE_CONSTANTS.NAME, {
      landingMode: PHASE_CONSTANTS.GETTERS.LANDING_MODE,
    }),

    globalDateFormat() {
      return this.getProp('admin_basics', 'date_format') || 'MM-DD-YYYY HH:mm';
    },
    isProdEnv() {
      return process.env.VUE_APP_DEPLOY_ENVIRONMENT === 'production';
    },
  },
  methods: {

    async logout() {
      await this.$store.dispatch('auth/logout', { returnToRoute: LandingRoutes[this.landingMode] })
        .then(() => this.$router.push(LandingRoutes[this.landingMode]));
    },

    filterByGroupVisibility(data, selectedGroup) {
      let group = selectedGroup;
      if (!group) group = this.cmsGroup;
      return (data || [])
        .filter(elem => !(elem.roles || []).length || (elem.roles || [])
          .find(g => {
            const lower = safeGet(g, 'directus_roles_id.name', '').toLowerCase();
            return lower && group.toLowerCase().includes(lower);
          }));
    },

    /**
     * gets text from provided collection or texts collection
     * fallback on default texts (by subgroup if provided)
     *
     * @param string key
     * @param string collection
     * @returns text value by key
     */
    getText(key, collection) {
      const table = this.$store.state.cms[collection] || this.texts;
      return table[key] || ((table.translations || {})[0] || {})[key]
        || (defaultTexts[collection] || {})[key];
    },

    goToPage(routeToGo, isNewTab = false) {
      if (!routeToGo) return;
      if (isNewTab) {
        if (routeToGo.indexOf('/') > -1) {
          const routeData = this.$router.resolve(routeToGo);
          window.open(routeData.href, '_blank');
          return;
        }
        const routeData = this.$router.resolve({ name: routeToGo });
        window.open(routeData.href, '_blank');
        return;
      }
      if (routeToGo.indexOf('/') > -1) {
        this.$router.push(routeToGo);
        return;
      }
      this.$router.push({ name: routeToGo });
    },
    goToURL(url, openNewWindow) {
      this.trackOutboundLink(url, this.$route.path);
      if (openNewWindow) {
        const win = window.open(url, '_blank');
        win.focus();
        return;
      }
      window.location.href = url;
    },
    urlWithProtocol(url) {
      return !/^https?:\/\//.test(url) ? `https://${url}` : url;
    },
    call(number) {
      this.goToURL(`tel:${number.replace(/ /g, '')}`, false);
    },
    handleLinkClick(link, linkMode) {
      if (linkMode && linkMode !== 'null') {
        switch (linkMode) {
          case 'same_tab':
            this.onCheckLink(link, false);
            break;
          case 'new_tab':
            this.onCheckLink(link, true);
            break;
          case 'layer':
            this.$modalPIP.show({ iFrameSrc: link });
            break;
          default:
            this.onCheckLink(link);
            break;
        }
        return;
      }
      this.onCheckLink(link);
    },
    onCheckLink(link, isNewTab = false) {
      if (this.isExternalLink(link)) {
        this.goToURL(link, isNewTab);
      } else {
        this.goToPage(link, isNewTab);
      }
    },
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    preventBodyScroll() {
      document.body.classList.add('scroll-off');
    },
    allowBodyScroll() {
      document.body.classList.remove('scroll-off');
    },
    isExternalLink: url => /^(http:\/\/|https:\/\/|mail:|tel:)+/.test(url),
    removeSpaces: item => typeof item === 'string' ? item.replace(/\s/g, '') : null,
    cmsFile(filename) { return filename ? `${this.cmsBasePath}/assets/${filename}` : null; },
    safeGet,
  },
};
