<template>
  <div
    :class="{ 'is-focus': focused, 'is-dirty': dirty }"
    class="app-input"
  >
    <label
      v-if="label"
      class="app-input__label"
    >
      {{ label }}
    </label>
    <input
      ref="input"
      :class="inputClass"
      :value="value"
      :type="$attrs.type || 'text'"
      v-bind="$attrs"
      v-on="{
        ...$listeners,
        blur: onBlur,
        focus: onFocus,
        input: onInput,
        change: onChange,
      }"
    >
  </div>
</template>

<script>
  export default {
    name: 'AppInput',
    inheritAttrs: false,
    props: {
      label: {
        type: String,
        required: false,
        default: '',
      },
      value: {
        type: String,
        required: false,
      },
      inputClass: {
        type: [Array, String],
        required: false,
        default: '',
      },
    },
    data() {
      return {
        focused: false,
        dirty: false,
      };
    },
    mounted() {
      this.dirty = !!(this.$refs.input.value || '').trim();
    },
    methods: {
      onBlur() {
        this.focused = false;
      },
      onFocus() {
        this.focused = true;
      },
      onInput(event) {
        this.dirty = !!(event.target.value || '').trim();
        this.$emit('input', event.target.value);
      },
      onChange(event) {
        this.$emit('change', event.target.value);
      },
    },
  };
</script>

<style lang="scss">
  @import 'src/assets/scss/abstracts/_mixins';
  @import 'src/assets/scss/abstracts/_variables';

  @import 'src/components/common/input/input';

  // Themes
  @import 'src/components/common/input/audi';
  @import 'src/components/common/input/ava';
  @import 'src/components/common/input/iqos';
  @import 'src/components/common/input/vw';
</style>
