import Vue from 'vue';
import Router from 'vue-router';

import Home from '@/pages/home/Home';
import Login from '@/pages/login/Login';
import LandingCountdown from '@/pages/countdown/LandingCountdown';

const Contacts = () => import(/* webpackChunkName: "pages" */ '@/pages/contacts/Contacts');
const ContentHub = () => import(/* webpackChunkName: "pages" */ '@/pages/contenthub/ContentHub');
const ContentHub2 = () => import(/* webpackChunkName: "pages" */ '@/pages/contenthub2/ContentHub');
const Diary = () => import(/* webpackChunkName: "pages" */ '@/pages/diary/Diary');
const DidYouKnow = () => import(/* webpackChunkName: "pages" */ '@/pages/didyouknow/DidYouKnow');
const Events = () => import(/* webpackChunkName: "pages" */ '@/pages/events/Events');
const Experts = () => import(/* webpackChunkName: "pages" */ '@/pages/experts/Experts');
const Floorplans = () => import(/* webpackChunkName: "pages" */ '@/pages/floorplans/Floorplans');
const ImagePages = () => import(/* webpackChunkName: "pages" */ '@/pages/image-pages/ImagePages');
const Information = () => import(/* webpackChunkName: "pages" */ '@/pages/information/Information');
const InternalDocuments = () => import(/* webpackChunkName: "pages" */ '@/pages/internaldocuments/InternalDocuments');
const Journalists = () => import(/* webpackChunkName: "pages" */ '@/pages/journalists/Journalists');
const Locations = () => import(/* webpackChunkName: "pages" */ '@/pages/locations/Locations');
const Newsletter = () => import(/* webpackChunkName: "pages" */ '@/pages/newsletter/Newsletter');
const PressKit = () => import(/* webpackChunkName: "pages" */ '@/pages/presskit/PressKit');
const ProductImages = () => import(/* webpackChunkName: "pages" */ '@/pages/productimages/ProductImages');
const Routes = () => import(/* webpackChunkName: "pages" */ '@/pages/routes/Routes');
const Schedule = () => import(/* webpackChunkName: "pages" */ '@/pages/schedule/Schedule');
const SneakPeek = () => import(/* webpackChunkName: "pages" */ '@/pages/sneakpeek/SneakPeek');
const Speakers = () => import(/* webpackChunkName: "pages" */ '@/pages/speakers/Speakers');
const Styleguide = () => import(/* webpackChunkName: "pages" */ '@/pages/styleguide/Styleguide');
const Survey = () => import(/* webpackChunkName: "pages" */ '@/pages/survey/Survey');
const Videos = () => import(/* webpackChunkName: "pages" */ '@/pages/videos/Videos');
const Weather = () => import(/* webpackChunkName: "pages" */ '@/pages/weather/Weather');
const WhosWho = () => import(/* webpackChunkName: "pages" */ '@/pages/whoiswho/WhosWho');

Vue.use(Router);

export default new Router({
  base: process.env.VUE_APP_BASE_URL,
  mode: 'history',
  scrollBehavior(to, from) {
    if (from.path !== to.path) {
      return { x: 0, y: 0 };
    }
    return null;
  },
  routes: [
    {
      path: '/',
      component: Home,
      name: 'Home',
      meta: { auth: true, type: 'home' },
    },
    {
      path: '/login',
      component: Login,
      name: 'Login',
      meta: { type: 'landing', hideNavigation: true },
    },
    {
      path: '/countdown',
      component: LandingCountdown,
      name: 'Countdown',
      meta: { type: 'landing', hideNavigation: true },
    },

    {
      path: '/newsletter',
      component: Newsletter,
      name: 'Newsletter',
      meta: { type: 'landing', hideNavigation: true },
    },
    {
      path: '/locations',
      name: 'Locations',
      component: Locations,
      meta: { auth: true },
    },
    {
      path: '/sneak-peek',
      name: 'SneakPeek',
      component: SneakPeek,
      meta: { auth: true },
    },
    {
      path: '/schedule',
      name: 'Schedule',
      component: Schedule,
      meta: { auth: true },
    },
    {
      path: '/information',
      name: 'Information',
      component: Information,
      meta: { auth: true },
    },
    {
      path: '/whoswho',
      component: WhosWho,
      name: 'WhosWho',
      meta: { auth: true },
    },
    {
      path: '/experts',
      component: Experts,
      name: 'Experts',
      meta: { auth: true },
    },
    {
      path: '/images',
      name: 'ProductImages',
      component: ProductImages,
      meta: { auth: true },
    },
    {
      path: '/presskit',
      component: PressKit,
      name: 'PressKit',
      meta: { auth: true },
    },
    {
      path: '/journalists',
      component: Journalists,
      name: 'Journalists',
      meta: { auth: true },
    },
    {
      path: '/routes',
      component: Routes,
      name: 'Routes',
      meta: { auth: true },
    },
    {
      path: '/documents',
      component: InternalDocuments,
      name: 'InternalDocuments',
      meta: { auth: true },
    },
    {
      path: '/didyouknow',
      component: DidYouKnow,
      name: 'DidYouKnow',
      meta: { auth: true },
    },
    {
      path: '/diary',
      component: Diary,
      name: 'Diary',
      meta: { auth: true },
    },
    {
      path: '/weather',
      component: Weather,
      name: 'Weather',
      meta: { auth: true },
    },
    {
      path: '/videos',
      name: 'Videos',
      component: Videos,
      meta: { auth: true },
    },
    {
      path: '/styleguide',
      component: Styleguide,
      name: 'Styleguide',
      meta: { auth: true, hideFooter: true, hideHeader: true },
    },
    {
      path: '/survey',
      component: Survey,
      name: 'Survey',
      meta: { auth: true },
    },
    {
      path: '/contacts',
      component: Contacts,
      name: 'Contacts',
      meta: { auth: true },
    },
    {
      path: '/floorplans',
      component: Floorplans,
      name: 'Floorplans',
      meta: { auth: true, hideFooter: true, hideHeader: true },
    },
    {
      path: '/contenthub',
      component: ContentHub,
      name: 'ContentHub',
      meta: { auth: true },
    },
    {
      path: '/contenthub2',
      component: ContentHub2,
      name: 'ContentHub2',
      meta: { auth: true },
    },
    {
      path: '/speakers',
      component: Speakers,
      name: 'Speakers',
      meta: { auth: true },
    },
    {
      path: '/events',
      component: Events,
      name: 'Events',
      meta: { auth: true },
    },
    {
      path: '/imagepage/:title',
      component: ImagePages,
      name: 'ImagePages',
      meta: { auth: true },
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
});
