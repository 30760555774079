export default {
  'app-container-width': 'none',

  'body-base-color': '#000',
  'body-background-color': '#000',

  'font-family--header': '"VW Head Bold", sans-serif',
  'font-family--interactive': '"VW Text Regular", sans-serif',
  'font-family--text-link': '"VW Text Bold", sans-serif',
  'font-family--bold': '"VW Text Bold", sans-serif',
  'font-family--body': '"VW Text Regular", sans-serif',
  'font-family--light': '"VW Head Light", sans-serif',

  'header-text-transform': 'none',

  'card-background-color': '#fff',
  'card-font-color': '#000',

  'header-background-color': '#fff',
  'header-font-color': '#fff',
  'header-icon-display': 'block',

  'contact-card-color': '#262626',
  'contact-card-background': 'transparent',

  'page-header-height': '140px',
  'page-header-height--medium': '340px',
  'page-header-background-color': '#000',
  'page-header-color': '#fff',

  'home-tile-border-color': '#fff',
  'home-tile-border-width': '0.75rem',
  'home-tile-icons-visibility': 'visible',
  'home-tile-height': '160px',
  'home-tile-height--medium': '230px',

  'app-button-border-width': '2px', // keep the 'px' unit for calculations

  'app-button-bg-color--hover': '#001e50',
  'app-button-bg-color': '#001e50',
  'app-button-border-color--hover': '#001e50',
  'app-button-border-color': '#001e50',
  'app-button-color--hover': '#fff',
  'app-button-color': '#fff',
  'app-button-disabled-bg-color': '#6a767d',
  'app-button-disabled-border-color': '#6a767d',
  'app-button-disabled-color': '#000',
  'app-button-font-size': 'inherit',
  'app-button-line-height': '1.25',

  'app-button--primary-bg-color--hover': '#001e50',
  'app-button--primary-bg-color': '#001e50',
  'app-button--primary-border-color--hover': '#001e50',
  'app-button--primary-border-color': '#001e50',
  'app-button--primary-color--hover': '#fff',
  'app-button--primary-color': '#fff',
  'app-button--primary-disabled-bg-color': '#6a767d',
  'app-button--primary-disabled-border-color': '#6a767d',
  'app-button--primary-disabled-color': '#000',

  'app-button--secondary-bg-color--hover': '#fff',
  'app-button--secondary-bg-color': '#fff',
  'app-button--secondary-border-color--hover': '#001e50',
  'app-button--secondary-border-color': '#001e50',
  'app-button--secondary-color--hover': '#001e50',
  'app-button--secondary-color': '#001e50',
  'app-button--secondary-disabled-bg-color': '#fff',
  'app-button--secondary-disabled-border-color': '#6a767d',
  'app-button--secondary-disabled-color': '#6a767d',

  'app-button--outline-bg-color--hover': '#fff',
  'app-button--outline-bg-color': 'transparent',
  'app-button--outline-border-color--hover': '#fff',
  'app-button--outline-border-color': '#fff',
  'app-button--outline-border-width': '2px',
  'app-button--outline-color--hover': '#001e50',
  'app-button--outline-color': '#fff',
  'app-button--outline-disabled-bg-color': '#fff',
  'app-button--outline-disabled-border-color': '#4c4c4c',
  'app-button--outline-disabled-color': '#4c4c4c',

  'app-button-align': 'center',
  'app-button-color-squared': '#262626',
  'app-button-min-height': '44px',
  'app-button-min-width--medium': '9em',
  'app-button-min-width': '9em',
  'app-button-padding': '0 32px',
  'app-button-text-transform': 'none',

  'header-height': '55px',
  'footer-height': '150px',
  'header-height--medium': '99px',
  'footer-height--medium': '150px',

  'app-page-footer-background-color': '#fff',
  'app-page-footer-link-color': '#001e50',
  'app-page-footer-color': '#000',

  'link-color': '#333333',

  'body-base-font-size': '16px',
  'body-base-font-size--medium': '16px',
  'body-base-line-height': '1.5',
  'body-base-line-height--medium': '1.5',

  'rating-inactive': '#c4d0cd',
  'rating-active': '#000',

  'input-font-size': '16px',
  'input-background': '#fefefe',
  'input-height': '40px',
  'input-color': '#0a0a0a',
  'input-border-color': 'rgba(0,0,0,0.3)',
  'input-border-color--focus': 'rgba(0,0,0,0.3)',
  'input-placeholder-color': '#bbbbbb',
  'input-placeholder-text-transform': 'none',
  'input-padding': '0.5rem 0',
  'select-option-color': '#fff',
  'select-option-color--hover': '#ffffff',
  'select-option-background': '#001e50',
  'select-option-background--hover': '#0177cc',

  'compendium-card-color': '#fff',
  'compendium-card-background': '#666',
  'compendium-card-icon-color': '#fff',
  'compendium-card-button-color': '#fff',
  'compendium-card-button-background': '#666',
  'compendium-card-border-width': '0px',
  'compendium-card-border-color': 'transparent',

  'compendium-card-color--hover': '#fff',
  'compendium-card-background--hover': '#666',
  'compendium-card-icon-color--hover': '#fff',
  'compendium-card-button-color--hover': '#fff',
  'compendium-card-button-background--hover': '#666',
  'compendium-card-border-width--hover': '0px',
  'compendium-card-border-color--hover': 'transparent',

  'compendium-card-color-primary': '#fff',
  'compendium-card-background-primary': '#000',
  'compendium-card-icon-color-primary': '#fff',
  'compendium-card-button-color-primary': '#fff',
  'compendium-card-button-background-primary': '#000',

  'compendium-card-color-primary--hover': '#000',
  'compendium-card-background-primary--hover': '#fff',
  'compendium-card-icon-color-primary--hover': '#000',
  'compendium-card-button-color-primary--hover': '#000',
  'compendium-card-button-background-primary--hover': '#fff',
};
