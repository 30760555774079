<template lang="html">
  <div
    v-if="$route.meta.type !== 'landing' && scrollTop > 75"
    class="scroll-top"
    @click="goUp"
  >
    <div
      class="scroll-top__button"
    >
      <theme-icon
        class="scroll-top__arrow"
        color="#fff"
        image="arrow-scroll-top"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'ScrollToTop',
  data() {
    return {
      scrollTop: 0,
    };
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    goUp(e) {
      e.preventDefault();
      this.trackOutboundLink('ScrollToTop', this.$route.path, 'Navigation');
      this.$scrollTo('#body');
    },
    handleScroll() {
      this.scrollTop = document.body.scrollTop
        ? document.body.scrollTop : document.documentElement.scrollTop;
    },
  },
};
</script>

<style lang="scss">
@import 'src/components/common/scrolltotop/scrolltotop';

// Themes
@import 'src/components/common/scrolltotop/audi';
@import 'src/components/common/scrolltotop/ava';
@import 'src/components/common/scrolltotop/bmw-group';
@import 'src/components/common/scrolltotop/bmw-mini';
@import 'src/components/common/scrolltotop/corporate';
@import 'src/components/common/scrolltotop/hyu-standard';
</style>
