import Vue from 'vue';
import store from '@/store';
import router from '@/router';

const registerModule = (name, module, parent) => {
  // Add store
  if (module.store) {
    store.registerModule(parent ? [parent, name] : name, module.store, {
      preserveState: localStorage.getItem('vuex') !== null,
    });
  }
  // Add components
  if (module.components) {
    Object.keys(module.components).forEach(componentKey => {
      const component = module.components[componentKey];
      Vue.component(componentKey, component);
    });
  }
  // Add routes
  if (module.routes) {
    module.routes.forEach(route => {
      if (route.parent && route.route) {
        router.addRoute(route.parent, route.route);
      } else {
        router.addRoute(route);
      }
    });
  }
  // Alter routes is not supported in vue router 3
  // Workaround for exisiting routes (subroutes)
  if (module.redirects) {
    router.beforeEach(async (to, from, next) => {
      Object.keys(module.redirects).forEach(routeName => {
        if (to.name === routeName) {
          next({
            path: module.redirects[routeName],
            params: to.params,
            query: to.query,
            replace: true,
          });
        }
        next();
      });
    });
  }
};

export const registerModules = (modules, parent) => {
  Object.keys(modules).forEach(moduleKey => {
    const module = modules[moduleKey];
    registerModule(moduleKey, module, parent);
  });
};

export default registerModules;
