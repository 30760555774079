import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import googleAnalyticsOptions from '@/helper/googleAnalytics';
import matomoAnalyticsOptions from '@/helper/matomoAnalytics';
import matomoTagManagerAnalyticsOptions from '@/helper/matomoTagManager';
import router from '@/router';
import store from '@/store';

Vue.prototype.$tracking = {
  set: () => {},
  event: () => {},
  page: () => {},
};

export default {
  name: 'Tracking',
  data() {
    return {
      trackingTypeAgent: 'none',
    };
  },
  methods: {
    getPageTrackingOptions() {
      const { state } = store;
      return {
        page: window.location.pathname,
        title: document.title,
        location: `${window.location.origin}${window.location.pathname}`,
        dimension1: state.cms.authenticated ? state.cms.group
        + (state.journalistGroupNumber ? `-${state.journalistGroupNumber}` : '') : 'unauthorized',
      };
    },
    trackOutboundLink(link, label, category = 'LinkOutbound') {
      this.$tracking.event({
        eventCategory: category,
        eventAction: link,
        eventLabel: label,
        location: window.location.href,
        transport: 'beacon',
      });
    },
    getMatomo() {
      return window.Piwik.getAsyncTracker();
    },
    setGoogleTracking(trackId) {
      const trackingId = this.isProdEnv
        ? trackId
        : process.env.VUE_APP_GA_TRACKING_ID_DEV;
      Vue.use(
VueAnalytics,
        googleAnalyticsOptions(router, trackingId, this.getPageTrackingOptions),
);
    },
    setMatomoTracking(trackId, trackingUrl) {
      const trackingId = this.isProdEnv
        ? trackId
        : process.env.VUE_APP_MATOMO_TRACKING_ID_DEV;
      const callback = () => {
        const matomo = this.getMatomo();

        Vue.prototype.$tracking = {
          set: (customDimensionId, customDimensionValue) => {
            const dimentionNum = customDimensionId.replace(/[a-zA-z]/g, '');
            matomo.setCustomDimension(dimentionNum, customDimensionValue);
          },
          event: ({
            eventCategory, eventAction, eventLabel = 'label',
          }) => {
            matomo.trackEvent(eventCategory, eventAction, eventLabel);
          },
          page: data => {
            const { title, location } = data;
            Object.keys(data).map(el => {
              if (el.indexOf('dimension') > -1) {
                const dimentionNum = el.replace(/[a-zA-z]/g, '');
                matomo.setCustomDimension(dimentionNum, data[el]);
              }
              return el;
            });
            matomo.setCustomUrl(`${location}`);
            matomo.trackPageView(title);
          },
        };
        router.afterEach(to => {
          if (to.path === '/login') {
            return;
          }
          this.$nextTick(() => {
            this.$tracking.page(this.getPageTrackingOptions());
          });
        });
      };
      return matomoAnalyticsOptions(trackingId, callback, trackingUrl);
    },
    setTracking(adminBasics) {
      const trackingType = adminBasics.tracking_type;
      const trackingId = adminBasics.tracking_id;
      const containerIds = adminBasics.container_ids;
      const trackingUrl = adminBasics.tracking_url;
      this.trackingTypeAgent = trackingType;
      window.processEnv = process.env;
      if (trackingType === 'ga') {
        this.setGoogleTracking(trackingId);
        this.$tracking.page = this.$ga.page;
        this.$tracking.event = this.$ga.event;
        this.$tracking.set = this.$ga.set;
      } else if (trackingType === 'ma') {
        this.setMatomoTracking(trackingId, trackingUrl);
      }
      if (containerIds && containerIds.length) {
        matomoTagManagerAnalyticsOptions(containerIds);
      }
    },
  },
};
