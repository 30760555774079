var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-input",class:{ 'is-focus': _vm.focused, 'is-dirty': _vm.dirty }},[(_vm.label)?_c('label',{staticClass:"app-input__label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('input',_vm._g(_vm._b({ref:"input",class:_vm.inputClass,attrs:{"type":_vm.$attrs.type || 'text'},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),{
      ..._vm.$listeners,
      blur: _vm.onBlur,
      focus: _vm.onFocus,
      input: _vm.onInput,
      change: _vm.onChange,
    }))])
}
var staticRenderFns = []

export { render, staticRenderFns }