export default {
  'app-container-width': 'none',

  'body-base-color': '#262626',
  'body-background-color': '#262626',

  'font-family--header': '"BMW_NEXT_LIGHT", sans-serif',
  'font-family--interactive': '"BMW_NEXT_BOLD", sans-serif',
  'font-family--text-link': '"BMW_NEXT_BOLD", sans-serif',
  'font-family--bold': '"BMW_NEXT_BOLD", sans-serif',
  'font-family--body': '"BMW_NEXT_LIGHT", sans-serif',
  'font-family--light': '"BMW_NEXT_LIGHT", sans-serif',

  'header-text-transform': 'uppercase',

  'card-background-color': '#ffffff',
  'card-font-color': '#262626',

  'header-background-color': '#f8f8f8',
  'header-font-color': '#FFF',
  'header-icon-display': 'block',

  'contact-card-color': '#262626',
  'contact-card-background': 'transparent',

  'page-header-height': '140px',
  'page-header-height--medium': '340px',
  'page-header-background-color': '#262626',
  'page-header-color': '#fff',

  'home-tile-border-color': '#000',
  'home-tile-border-width': '1px',
  'home-tile-icons-visibility': 'visible',
  'home-tile-height': '135px',
  'home-tile-height--medium': '240px',

  'app-button-border-width': '0px', // keep the 'px' unit for calculations

  'app-button-bg-color--hover': '#262626',
  'app-button-bg-color': '#4d4d4d',
  'app-button-border-color--hover': '#4d4d4d',
  'app-button-border-color': '#666',
  'app-button-color--hover': '#fff',
  'app-button-color': '#fff',
  'app-button-disabled-bg-color': 'rgba(77,77,77,0.3)',
  'app-button-disabled-color': '#fff',
  'app-button-font-size': 'inherit',
  'app-button-line-height': '1',

  'app-button--primary-bg-color--hover': '#0653b6',
  'app-button--primary-bg-color': '#1c69d4',
  'app-button--primary-border-color--hover': '#4d4d4d',
  'app-button--primary-border-color': '#666',
  'app-button--primary-color--hover': '#fff',
  'app-button--primary-color': '#fff',
  'app-button--primary-disabled-bg-color': 'rgba(28,105,212,0.3)',
  'app-button--primary-disabled-color': '#fff',

  'app-button--secondary-bg-color--hover': '#262626',
  'app-button--secondary-bg-color': '#4d4d4d',
  'app-button--secondary-border-color--hover': '#4d4d4d',
  'app-button--secondary-border-color': '#666',
  'app-button--secondary-color--hover': '#fff',
  'app-button--secondary-color': '#fff',
  'app-button--secondary-disabled-bg-color': 'rgba(77,77,77,0.3)',
  'app-button--secondary-disabled-color': '#fff',

  'app-button--outline-bg-color--hover': '#fff',
  'app-button--outline-bg-color': 'transparent',
  'app-button--outline-border-color--hover': '#fff',
  'app-button--outline-border-color': '#fff',
  'app-button--outline-border-width': '2px',
  'app-button--outline-color--hover': '#262626',
  'app-button--outline-color': '#fff',

  'app-button-align': 'center',
  'app-button-color-squared': '#262626',
  'app-button-min-height': '50px',
  'app-button-min-width--medium': '286px',
  'app-button-min-width': '100%',
  'app-button-padding': '0 16px',
  'app-button-text-transform': 'none',

  'header-height': '55px',
  'footer-height': '150px',
  'header-height--medium': '99px',
  'footer-height--medium': '150px',

  'app-page-footer-background-color': '#262626',
  'app-page-footer-link-color': '#8e8e8e',
  'app-page-footer-color': '#8e8e8e',

  'link-color': '#333333',

  'body-base-font-size': '15px',
  'body-base-font-size--medium': '15px',
  'body-base-line-height': '1.5',
  'body-base-line-height--medium': '1.5',

  'rating-inactive': '#c4d0cd',
  'rating-active': '#000',

  'input-font-size': '1rem',
  'input-background': '#fefefe',
  'input-height': '50px',
  'input-color': '#0a0a0a',
  'input-border-color': '#bbbbbb',
  'input-border-color--focus': '#000000',
  'input-placeholder-color': '#bbbbbb',
  'input-placeholder-text-transform': 'none',
  'input-padding': '0.5rem 1.5rem',
  'select-option-color': '#bbbbbb',
  'select-option-color--hover': '#ffffff',
  'select-option-background': '#ffffff',
  'select-option-background--hover': '#bbbbbb',

  'compendium-card-color': '#fff',
  'compendium-card-background': '#666',
  'compendium-card-icon-color': '#fff',
  'compendium-card-button-color': '#fff',
  'compendium-card-button-background': '#666',
  'compendium-card-border-width': '0px',
  'compendium-card-border-color': 'transparent',

  'compendium-card-color--hover': '#fff',
  'compendium-card-background--hover': '#666',
  'compendium-card-icon-color--hover': '#fff',
  'compendium-card-button-color--hover': '#fff',
  'compendium-card-button-background--hover': '#666',
  'compendium-card-border-width--hover': '0px',
  'compendium-card-border-color--hover': 'transparent',

  'compendium-card-color-primary': '#fff',
  'compendium-card-background-primary': '#000',
  'compendium-card-icon-color-primary': '#fff',
  'compendium-card-button-color-primary': '#fff',
  'compendium-card-button-background-primary': '#000',

  'compendium-card-color-primary--hover': '#000',
  'compendium-card-background-primary--hover': '#fff',
  'compendium-card-icon-color-primary--hover': '#000',
  'compendium-card-button-color-primary--hover': '#000',
  'compendium-card-button-background-primary--hover': '#fff',
};
