import store from '@/store';
import service from './service';

const log = (txt, params) => store.state.debug && console.log(`OIDC Auth Module: ${txt}`, params);

export default {
  namespaced: true,

  actions: {
    /**
     * - Attempt to refresh cms token to trigger authenticated state setting
     * needed in oidc mode, because no login action is performed
     * - Triggers fetching of cms user data to set group
     *
     * @returns {Promise<void>}
     */
    async init({ dispatch, rootState }) {
      log('init');
      try {
        // trigger token refresh on page load
        // to set authenticated state after redirect to app
        if (!rootState.cms.authenticated) {
          await dispatch('cms/refresh', {}, { root: true });
        }
        // trigger load cms user group (ir-vuex-directus /me)
        await dispatch('cms/me', {}, { root: true });
      } catch (err) {
        // throw error just in case the services are not available
        if (!err.response || err?.response?.status === 404) throw new Error('CMS not available');
      }
    },

    async loginInit() {
      log('login init');
      // TBD: autologin
      // return service.login({ returnToRoute });
    },

    /**
     * Calls service login with redirect param to the current app
     *
     * @param { string } returnToRoute url to return to after login
     *
     * @returns {Promise<void>}
     */
    login(_, { returnToRoute }) {
      log('login', returnToRoute);
      return service.login({ returnToRoute });
    },

    /**
     * Calls service logout with redirect param to the current app
     *
     * @param { string } returnToRoute url to return to after login
     *
     * @returns {Promise<void>}
     */
    logout(_, { returnToRoute }) {
      log('logout', returnToRoute);
      return service.logout({ returnToRoute });
    },

    /**
     * Calls service user data action
     *
     * @returns {Promise<User>} user data
     */
    async user() {
      log('get user');
      const { data } = await service.getUser();
      return data;
    },
  },
};
