<template lang="html">
  <section class="page-header-text">
    <h2
      v-if="headerText"
      class="page-header-text__headline-container"
    >
      <div
        v-if="icon"
        class="page-header-text__icon-wrapper"
      >
        <theme-icon
          :image="icon"
          icon-class="page-header-text__icon-svg"
        />
      </div>
      <span
        v-sane-html="headerText"
        class="page-header-text__headline"
      />
    </h2>
    <div
      :style="`--background: url('${image}')`"
      :class="[ 'page-header-text__background-container', {
        'page-header-text__margin': margin, classPageHeader }
      ]"
    >
      <div class="page-header-text__overlay" />
    </div>
    <div
      v-sane-html="headerCopy"
      class="page-header-text__subtext"
    />
  </section>
</template>

<script>
import skinner from '@/components/mixins/skinner';

export default {
  name: 'PageHeaderText',
  mixins: [skinner],
  props: {
    headerText: {
      type: String,
      default: '',
    },
    headerCopy: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    margin: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: '',
    },
    classPageHeader: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/abstracts/_mixins';
@import 'src/assets/scss/abstracts/_variables';

@import 'src/components/common/pageheadertext/pageheadertext';

// Themes
@import 'src/components/common/pageheadertext/audi';
@import 'src/components/common/pageheadertext/ava';
@import 'src/components/common/pageheadertext/bmw-group';
@import 'src/components/common/pageheadertext/bmw-mini';
@import 'src/components/common/pageheadertext/bmw-motorrad';
@import 'src/components/common/pageheadertext/corporate';
@import 'src/components/common/pageheadertext/corvette';
@import 'src/components/common/pageheadertext/hyu-standard';
@import 'src/components/common/pageheadertext/vw';

// anonimize overrides
@import 'src/components/common/pageheadertext/anon';
</style>
