export default {
  'app-container-width': 'none',

  'body-base-color': '#1c1b1b',
  'body-background-color': '#323232',

  'font-family--header': '"Oxygen", sans-serif',
  'font-family--interactive': '"Oxygen", sans-serif',
  'font-family--text-link': '"Oxygen", sans-serif',
  'font-family--bold': '"Oxygen", sans-serif',
  'font-family--body': '"Oxygen", sans-serif',
  'font-family--light': '"Oxygen", sans-serif',

  'header-text-transform': 'normal',

  'card-background-color': '#ffffff',
  'card-font-color': '#1c1b1b',

  'header-background-color': 'transparent',
  'header-font-color': '#002c5f',
  'header-icon-display': 'none',

  'contact-card-color': '#000',
  'contact-card-background': '#f6f3f2',

  'page-header-height': '115px',
  'page-header-height--medium': '270px',
  'page-header-background-color': '#262626',
  'page-header-color': '#fff',

  'home-tile-border-color': 'transparent',
  'home-tile-border-width': '0px',
  'home-tile-icons-visibility': 'visible',
  'home-tile-height': '135px',
  'home-tile-height--medium': '240px',

  'app-button-border-width': '0px', // keep the 'px' unit for calculations

  'app-button-color': '#fff',
  'app-button-bg-color': '#002c5f',
  'app-button-color--hover': '#fff',
  'app-button-bg-color--hover': '#013c78',
  'app-button-disabled-color': '#fff',
  'app-button-disabled-bg-color': '#002c5f',
  'app-button-font-size': 'inherit',
  'app-button-line-height': '1',

  'app-button--primary-color': '#fff',
  'app-button--primary-bg-color': '#002c5f',
  'app-button--primary-color--hover': '#fff',
  'app-button--primary-bg-color--hover': '#013c78',
  'app-button--primary-disabled-color': '#fff',
  'app-button--primary-disabled-bg-color': '#002c5f',

  'app-button--secondary-bg-color': '#666',
  'app-button--secondary-bg-color--hover': '#4d4d4d',
  'app-button--secondary-color': '#FFF',
  'app-button--secondary-color--hover': '#fff',
  'app-button--secondary-disabled-color': '#fff',
  'app-button--secondary-disabled-bg-color': '#666',

  'app-button--outline-bg-color': 'transparent',
  'app-button--outline-color': '#fff',
  'app-button--outline-bg-color--hover': '#fff',
  'app-button--outline-color--hover': '#000',
  'app-button--outline-border-width': '2px',
  'app-button--outline-border-color': '#fff',
  'app-button--outline-border-color--hover': '#fff',

  'app-button-color-squared': '#1c1b1b',
  'app-button-min-width': '0px',
  'app-button-min-width--medium': '260px',
  'app-button-padding': '0 15px',
  'app-button-text-transform': 'none',
  'app-button-align': 'center',

  'header-height': '60px',
  'footer-height': '250px',
  'header-height--medium': '80px',
  'footer-height--medium': '200px',

  'app-page-footer-background-color': '#323232',
  'app-page-footer-link-color': '#fff',
  'app-page-footer-color': '#fff',

  'link-color': '#333333',

  'body-base-font-size': '16px',
  'body-base-font-size--medium': '16px',
  'body-base-line-height': '1.5',
  'body-base-line-height--medium': '1.5',

  'rating-inactive': '#c4d0cd',
  'rating-active': '#000',

  'input-font-size': '1rem',
  'input-background': '#fefefe',
  'input-height': 'auto',
  'input-color': '#0a0a0a',
  'input-border-color': '#0a0a0a',
  'input-placeholder-color': '#0a0a0a',
  'input-placeholder-text-transform': 'none',
  'input-padding': '0.5rem',
  'select-option-color': '#0a0a0a',
  'select-option-color--hover': '#ffffff',
  'select-option-background': '#ffffff',
  'select-option-background--hover': '#0a0a0a',

  'compendium-card-color': '#fff',
  'compendium-card-background': '#002c5f',
  'compendium-card-icon-color': '#fff',
  'compendium-card-button-color': '#ff',
  'compendium-card-button-background': '#002c5f',
  'compendium-card-border-width': '0px',
  'compendium-card-border-color': 'transparent',

  'compendium-card-color--hover': '#fff',
  'compendium-card-background--hover': '#002c5f',
  'compendium-card-icon-color--hover': '#fff',
  'compendium-card-button-color--hover': '#ff',
  'compendium-card-button-background--hover': '#002c5f',
  'compendium-card-border-width--hover': '0px',
  'compendium-card-border-color--hover': 'transparent',

  'compendium-card-color-primary': '#fff',
  'compendium-card-background-primary': '#000',
  'compendium-card-icon-color-primary': '#fff',
  'compendium-card-button-color-primary': '#fff',
  'compendium-card-button-background-primary': '#000',

  'compendium-card-color-primary--hover': '#000',
  'compendium-card-background-primary--hover': '#fff',
  'compendium-card-icon-color-primary--hover': '#000',
  'compendium-card-button-color-primary--hover': '#000',
  'compendium-card-button-background-primary--hover': '#fff',
};
