<template>
  <component
    :is="homeComponent"
    :pages="pages"
  />
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Home',

  computed: {
    ...mapState(['homeMode', 'fullPagesHome']),

    homeComponent() {
      return this.importComponent(this.homeMode);
    },

    pages() {
      // currently used for debug only: list existing routes
      return this.fullPagesHome ? this.$router.options.routes.filter(item => item.name) : [];
    },
  },

  methods: {
    importComponent(type) {
      return () => import(`@/pages/home/${type || 'press'}/Home.vue`);
    },
  },

};
</script>
