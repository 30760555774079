const deviceType = {
  install(Vue) {
    const setVue = Vue;
    setVue.prototype.$detectDevice = () => {
      // Detect mobile device
      const isMobile = {
        Android() {
          return navigator.userAgent.match(/Android/i);
        },
        BlackBerry() {
          return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS() {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera() {
          return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows() {
          return navigator.userAgent.match(/IEMobile/i);
        },
        any() {
          return (
            isMobile.Android()
            || isMobile.BlackBerry()
            || isMobile.iOS()
            || isMobile.Opera()
            || isMobile.Windows()
          );
        },
      };
      return isMobile;
    };
  },
};

// Usage example:
// const device = this.$detectDevice();
export default deviceType;
