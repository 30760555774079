<template>
  <landing-layout
    :heading="getText('landing_countdown_heading')"
    :copy="getText('landing_countdown_copy')"
    class="landing-countdown"
  >
    <div class="landinglayout__container">
      <countdown />
    </div>
  </landing-layout>
</template>

<script>
import LandingLayout from '@/layouts/landinglayout/LandingLayout';

// components
import Countdown from '@/components/common/countdown/Countdown';

export default {
  name: 'LandingCountdown',

  components: {
    LandingLayout,
    Countdown,
  },
};
</script>

<style lang="scss">
@import '~foundation-sites/scss/foundation';
@import 'src/assets/scss/abstracts/variables';
@import 'src/assets/scss/abstracts/mixins';

@import 'src/pages/countdown/landing-countdown';

</style>
