const ApiErrors = Object.freeze({
  LOCKED: 'Locked',
  CONFLICT: 'Conflict',
  CMS_LOGIN_FAILED: 'CMS login failed',
  PASSWORD_ATTEMPTS_EXCEEDED: 'Password attempts exceeded',
  USER_NOT_FOUND: 'User not found',
  CODE_INVALID: 'Code invalid',
  CODE_ALREADY_USED: 'Code already used',
});

const ConfigurationErrors = Object.freeze({
  OPERATION_NOT_SUPPORTED: 'Operation not supported',
});

export { ApiErrors, ConfigurationErrors };
