export default (router, id, getPageTrackingOptions) => ({
  id: id,
  router,
  // debug: {
  //   enabled: true,
  //   trace: false,
  //   sendHitTask: true,
  // },
  set: [
    { field: 'anonymizeIp', value: true },
  ],
  autoTracking: {
    exception: true,
    page: true,
    pageviewOnLoad: true,
    pageviewTemplate() {
      return getPageTrackingOptions();
    },
  },
});
