export default {
  agenda_preview_item_count: '2',
  back_to_home: 'back to home',
  browser_warning_headline: '',
  browser_warning_text: '',
  config_not_supported: 'Operation not supported',
  contacts_contacts_list: 'Contacts',
  contacts_keyword_suggestions: 'Keyword suggestions',
  contacts_no_results: 'Your search did not match any results',
  contacts_recent: 'Recent',
  contacts_reset_search: 'Reset search',
  contacts_show_less: 'Show less',
  contacts_show_more: 'Show more',
  content_hub_downloads_headline: 'Downloads',
  cookiebar_content: 'We use cookies to personalize content and to analyze our traffic. You consent to our cookies if you continue to use our website.',
  cookiebar_header: 'The Website uses cookies',
  countdown_days: '',
  countdown_hours: '',
  countdown_minutes: '',
  countdown_seconds: '',
  events_error: 'Error fetching events, please try again later',
  footer_copyright: '',
  footer_signout: 'Sign Out',
  home_compendium_title: 'Event information',
  home_events_headline: 'Your next live sessions',
  home_events_join: 'Join',
  home_events_starting_in: 'Starting in',
  home_schedule_label: 'Agenda',
  hotline_label: 'Event hotline',
  hotline_number: '',
  image_download: 'Download image',
  journalists_listing_header: 'Journalist lists',
  landing_countdown_copy: 'Starting in',
  landing_countdown_heading: 'Event',
  product_images_download_all_label: 'Download all images',
  product_images_download_all_url: '',
  product_images_label: 'High resolution media downloads',
  product_images_loading: '',
  schedule_header_pdf_download: 'Downloads',
  schedule_not_available: 'No schedule available for this group',
  select_schedule: 'Show content for event group',
  timezone_note_title: '',
  timezone_note: '',
  video_download: 'Download',
  video_player_unmute_btn_label: 'Unmute',
  video_player_unmute_text_label: 'Stream is now live. Please unmute to hear the audio.',
  video_player_unmute_title_label: 'Audio disabled',
  weather_header: 'Weather',
  login: {
    copy: '',
    email_missing_error_message: 'Please enter your email address',
    email_placeholder: 'Email',
    login_error_cms: 'User configuration error (CMS auth failed)',
    login_error_generic: 'Login failed. Incorrect username or password, or account is deactivated.',
    login_error_message: 'Login failed. Incorrect username or password, or account is deactivated.',
    login_error_password_attempts_exceeded: 'Too many failed login attempts. Please try again later.',
    password_missing_error_message: 'Please enter your password',
    password_placeholder: 'Password',
    password_reset_attempts_exceeded: 'Too many failed login attempts. Please try again later.',
    password_reset_confirmation_placeholder: 'Password Confirmation',
    password_reset_enter_now_button_label: 'Enter now',
    password_reset_generic_error: 'Password reset failed',
    password_reset_password_confirmation_missing_error_message: 'Please enter a password confirmation',
    password_reset_password_missing_error_message: 'Please enter a password',
    password_reset_password_note: 'Password requirements:',
    password_reset_password_too_weak_error_message: 'The password does not meet the password policy:',
    password_reset_passwords_dont_match_error_message: "Passwords don't match",
    password_reset_placeholder: 'New password',
    password_reset_reset_code_already_used: 'Reset code has already been used',
    password_reset_reset_code_invalid: 'Reset code not valid',
    password_reset_submit_label: 'Send',
    password_reset_success_message: 'Password successfully changed',
    password_reset_user_not_found: 'User not found',
    password_rule_text_minimumlength: 'mininum length',
    password_rule_text_requirelowercase: 'includes lower case letters',
    password_rule_text_requirenumbers: 'includes numbers',
    password_rule_text_requiresymbols: 'includes special characters',
    password_rule_text_requireuppercase: 'includes upper case letters',
    request_password_back_to_login_link_label: 'Back to Login',
    request_password_email_placeholder: 'Email address',
    request_password_reset_email_invalid_error_message: 'Email address not valid',
    request_password_reset_email_missing_error_message: 'Please enter an email address',
    request_password_reset_generic_error: 'Request failed',
    request_password_reset_submit_label: 'Send',
    request_password_reset_success_message: 'A secure link to reset your password has been sent to your email address',
    submit_label: 'Sign in',
    welcome: '',
  },
  registration: {
    copy: '',
    email_invalid_error_message: 'Please enter a valid email address.',
    email_missing_error_message: 'Please enter an email address.',
    email_placeholder: 'Email',
    event_locked_error_message: 'Event is locked for registrations.',
    first_name_missing_error_message: 'Please enter a first name.',
    first_name_placeholder: 'First name',
    generic_error_message: 'An error occurred.',
    honorific_missing_error_message: 'Please select a honorific.',
    honorific_options: [
      { option: 'Mr' },
      { option: 'Mrs' },
      { option: 'Ms' },
      { option: 'Dr' },
      { option: 'Prof' },
    ],
    last_name_missing_error_message: 'Please enter a last name.',
    last_name_placeholder: 'Last name',
    password_missing_error_message: 'Please enter a password.',
    password_note: '',
    password_placeholder: 'Password',
    password_too_weak_error_message: 'The password does not meet the password policy:',
    registration_confirm_copy: '',
    registration_confirm_enter_now_button_label: 'Enter now',
    registration_confirm_generic_error: 'An error occurred.',
    registration_confirm_success_message: 'Confimation successful. You can sign in now.',
    registration_confirm_welcome: '',
    submit_label: 'Register',
    success_message: 'Your registration was successful.',
    tc_not_accepted_error_message: 'Please agree to the terms and conditions.',
    user_exists_error_message: 'The email address is already registered.',
    welcome: '',
  },
};
