<template>
  <landing-layout
    :heading="welcome"
    :copy="copy"
    class="login"
  >
    <div class="login__container landinglayout__container">
      <div class="login__form landinglayout__form">
        <h1
          v-if="welcome"
          v-sane-html="welcome"
          class="login__login-header hide-for-medium"
        />

        <h2
          v-if="copy"
          v-sane-html="copy"
          class="login__login-subheader hide-for-medium"
        />

        <h2
          class="login__login--title"
        >
          {{ loginTexts.title }}
        </h2>

        <div
          v-if="loginErrorMessage"
          class="login__error-message landinglayout__error-message"
        >
          {{ loginErrorMessage }}
        </div>

        <router-view
          @loginStart="loginStartCallback"
          @loginSuccess="loginSuccessCallback"
          @loginError="loginErrorCallback"
        />
      </div>
    </div>
  </landing-layout>
</template>

<script>
import { mapGetters } from 'vuex';

import { ApiErrors } from '@/util/errors';

import LandingLayout from '@/layouts/landinglayout/LandingLayout';

export default {
  name: 'Login',

  components: {
    LandingLayout,
  },

  data() {
    return {
      loginSuccess: false,
      loggingIn: false,
      loginErrorMessage: false,
      textCollection: 'login',
    };
  },

  computed: {
    ...mapGetters('auth', ['authenticated, loginMode']),

    loginTexts() {
      return this.getProp('login', 'translations[0]', {});
    },

    welcome() {
      return this.getText('welcome', this.textCollection);
    },

    copy() {
      return this.getText('copy', this.textCollection);
    },

    returnToRoute() {
      return this.$route.query.returnTo ?? { name: 'Home' };
    },
  },
  async mounted() {
    // if already authenticated, skip login and go to return route
    if (this.authenticated) this.$router.replace(this.returnToRoute);
  },
  methods: {
    // emitted by auth child components
    loginStartCallback() {
      this.loggingIn = true;
      this.loginErrorMessage = false;
    },

    // emitted by auth child components
    loginSuccessCallback() {
      this.loggingIn = false;
      this.loginSuccess = true;
      this.loginActionFinished();
      this.$router.push(this.returnToRoute);
    },

    // emitted by auth child components
    loginErrorCallback(err) {
      this.loggingIn = false;
      this.loginSuccess = false;
      this.loginActionFinished();

      switch (err.message) {
        case ApiErrors.CMS_LOGIN_FAILED:
          this.loginErrorMessage = this.getText('login_error_cms', this.textCollection);
          break;
        case ApiErrors.PASSWORD_ATTEMPTS_EXCEEDED:
          this.loginErrorMessage = this.getText('login_error_password_attempts_exceeded', this.textCollection);
          break;
        default: this.loginErrorMessage = this.getText('login_error_generic', this.textCollection);
      }
    },

    loginActionFinished() {
      this.$tracking.event({
        eventCategory: 'User',
        eventAction: this.loginSuccess ? 'Login' : 'LoginFailed',
        eventLabel: this.loginMode,
      });
    },
  },
};
</script>

<style lang="scss">
@import '~foundation-sites/scss/foundation';
@import 'src/assets/scss/abstracts/variables';
@import 'src/assets/scss/abstracts/mixins';

@import 'src/pages/login/login';

// Themes
@import 'src/pages/login/audi';
@import 'src/pages/login/bmw-car';
@import 'src/pages/login/bmw-group';
@import 'src/pages/login/bmw-m';
@import 'src/pages/login/bmw-mini';
@import 'src/pages/login/bmw-motorrad';
@import 'src/pages/login/corporate';
@import 'src/pages/login/corvette';
@import 'src/pages/login/hyu-standard';
@import 'src/pages/login/iqos';
</style>
