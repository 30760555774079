<template>
  <div
    :class="{ open }"
    class="phase-debug"
  >
    <div class="phase-debug__heading">
      PHASE
    </div>

    <div
      class="phase-debug__close"
      @click.stop="open = !open"
    >
      <theme-icon image="arrow-l" />
    </div>

    <dropdown :close-on-click="true">
      <template slot="btn">
        {{ currentPhase.name }}
      </template>

      <template slot="body">
        <div
          v-for="(value, index) in dropdownPhases"
          :key="index"
          @click="() => changeCurrentPhase(value)"
        >
          <span>{{ value.name }}</span>
        </div>
      </template>

      <template slot="icon">
        <div class="dropdown-icon">
          <theme-icon image="arrow-scroll-top" />
        </div>
      </template>
    </dropdown>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import Dropdown from 'bp-vuejs-dropdown';

// mixins
import { phaseMixin } from '@/helper/phasehelper';

// constants
import { PHASE_CONSTANTS } from '@/store/phasesModule';

export default {
  name: 'PhaseDebug',

  components: {
    Dropdown,
  },

  mixins: [phaseMixin],

  data: () => ({
    open: true,
  }),

  computed: {
    dropdownPhases() {
      return this.eventPhases.filter(phase => phase.name !== this.currentPhase.name);
    },
  },

  methods: {
    ...mapActions(PHASE_CONSTANTS.NAME, {
      changeCurrentPhase: PHASE_CONSTANTS.ACTIONS.CHANGE_CURRENT_PHASE,
    }),
  },

};
</script>

<style lang="scss">
@import 'src/components/common/debug/phase-debug';
</style>
