<template>
  <div
    :class="{ disabled }"
    class="custom-select"
    @blur="open = false"
  >
    <div
      :class="{ open: open }"
      class="selected"
      @click="!disabled && (open = !open)"
    >
      {{ selected && labelProperty ? selected[labelProperty] : selected }}
      <div class="arrow" />
    </div>
    <div
      :class="{ selectHide: !open }"
      class="items"
    >
      <div
        v-for="(option, i) in options"
        :key="i"
        @click="
          selected = option;
          open = false;
          $emit('input', option);
        "
      >
        {{ labelProperty ? option[labelProperty] : option }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Select',

  props: {
    value: {
      type: String,
      default: '',
    },

    options: {
      type: Array,
      required: true,
      default: () => [],
    },

    labelProperty: {
      type: String,
      default: '',
    },

    valueProperty: {
      type: String,
      default: '',
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selected: null,
      open: false,
    };
  },

  computed: {

    disabled() {
      return this.loading || this.options.length === 1;
    },
  },

  watch: {

    options(val) {
      if (!val || !val.length) return;
      this.setSelected();
    },
  },

  mounted() {
    this.setSelected();
  },

  methods: {
    setSelected() {
      const dev = this.options.find(
        v => (this.valueProperty ? v[this.valueProperty] : v) === this.value,
      );

      if (dev) this.selected = dev;
      else this.selected = this.options.length > 0 ? this.options[0] : null;

      this.$emit('input', this.selected);
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/abstracts/_mixins';
@import 'src/assets/scss/abstracts/_variables';

@import 'src/components/common/select/select';

// THEMES
@import 'src/components/common/select/audi';
@import 'src/components/common/select/ava';
@import 'src/components/common/select/iqos';
@import 'src/components/common/select/vw';
</style>
