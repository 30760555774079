<template lang="html">
  <header
    :class="[`header header__wrapper header--${$route.meta.type || ''}`]"
  >
    <div class="header__page-header">
      <div class="header__title-container">
        <img
          v-if="logoLeft"
          :src="logoLeft"
          :class="{ 'header__logo-link': !['home', 'landing'].includes($route.meta.type)}"
          alt="logo"
          class="header__logo-left"
          @click="back()"
        >
        <img
          v-if="logoLeftInverted"
          :src="logoLeftInverted"
          :class="{ 'header__logo-link': !['home', 'landing'].includes($route.meta.type)}"
          alt="logo"
          class="header__logo-left header__logo-left--inverted"
          @click="back()"
        >
        <span
          v-if="pageHeader"
          class="header__title"
        >
          {{ pageHeader }}
        </span>
        <span
          v-if="!['home', 'landing'].includes($route.meta.type)"
          class="header__back-link"
          @click="back()"
        >
          <theme-icon
            image="backLink"
            color="currentColor"
            class="header__icon-svg"
          />
          <span class="header__back-link-text">
            {{ getText('back_to_home') }}
          </span>
        </span>
      </div>

      <navigation-links
        v-if="navigationLinksEnabled"
        :navigation-links="navigationLinks"
      />

      <div
        class="header__brand-logo"
      >
        <a
          :is="logoLink ? 'a' : 'div'"
          v-if="logoRight"
          :href="logoLink"
          :target="logoLink ? '_blank' : undefined"
          class="header__brand-logo-wrapper"
        >
          <img
            :src="logoRight"
            class="header__brand-image"
            alt="en_EN"
          >
        </a>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';

import skinner from '@/components/mixins/skinner';

import NavigationLinks from '@/components/common/navigation/Navigation';

export default {
  name: 'MainHeader',
  components: {
    NavigationLinks,
  },
  mixins: [skinner],
  data() {
    return {
      isMobileNavOpen: false,
    };
  },
  computed: {
    ...mapState('cms', {
      navigationLinks: state => state.navigation_links || [],
      headerNavigationEnabled: state => state.home && state.home.length
        ? state.home[0].enable_header_navigation
        : false,
    }),
    ...mapState(['homeMode']),
    showNavigation() {
      return !this.$route.meta.hideNavigation;
    },
    pageHeader() {
      return this.getProp('basics', 'page_header');
    },
    logoLeft() {
      return this.getThemeLogos.left;
    },
    logoLeftInverted() {
      return this.getThemeLogos.inverted;
    },
    logoRight() {
      return this.getThemeLogos.right;
    },
    logoLink() {
      return this.getProp('basics', 'header_logo_right_link');
    },
    navigationLinksEnabled() {
      return this.navigationLinks.length
        && (this.homeMode === 'hybrid' || (this.homeMode === 'press' && this.headerNavigationEnabled))
        && this.showNavigation;
    },
  },

  beforeMount() {
    // Navigation Links don't seem to always exist.
    // TODO: Perhaps we can set this as an environment variable?
    // https://jira.avantgarde-digital.de/browse/SH-204
    // this.$store.dispatch('cms/getItems', {
    //   tableName: 'navigation_links', params: { fields: '*.*' }
    // });
  },

  methods: {
    back() {
      if (['Home'].includes(this.$route.name) || this.$route.meta.type === 'landing') return;
      this.$router.push({ path: '/' });
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/abstracts/_mixins';
@import 'src/assets/scss/abstracts/_variables';

@import 'src/components/common/header/header';

// Themes
@import 'src/components/common/header/audi';
@import 'src/components/common/header/ava';
@import 'src/components/common/header/bmw-car';
@import 'src/components/common/header/bmw-i';
@import 'src/components/common/header/bmw-m';
@import 'src/components/common/header/bmw-group';
@import 'src/components/common/header/bmw-mini';
@import 'src/components/common/header/bmw-motorrad';
@import 'src/components/common/header/corporate';
@import 'src/components/common/header/corvette';
@import 'src/components/common/header/hyu-standard';
@import 'src/components/common/header/iqos';
@import 'src/components/common/header/vw';
</style>
