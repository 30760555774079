import LoginForm from './components/login-form/LoginForm';

export default [
  {
    parent: 'Login',
    route: {
      path: 'default',
      component: LoginForm,
      meta: { type: 'landing', hideNavigation: true },
    },
  },
];
