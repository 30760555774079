import Vue from 'vue';
import VMediaQuery from 'v-media-query';

Vue.use(VMediaQuery, {
  methods: {
    pixelDensity(n) {
      // add min-resolution query to pixel-ratio > 1
      const minResolution = n > 1 ? `, (min-resolution: ${n === 2 ? '192' : '300'}dpi` : '';
      return matchMedia(`(-webkit-min-device-pixel-ratio: ${n})${minResolution}`).matches;
    },
  },
});

// DEFAULTS
const WIDTH_DESKTOP = 1440;
const HEIGHT_DESKTOP = 920;
const WIDTH_MOBILE = 300;
const HEIGHT_MOBILE = 200;
const MODE = 'outside';

export default {
  computed: {
    pixelDensity() {
      if (this.$mq.above('600px') || this.$mq.pixelDensity(3)) {
        return 3;
      } if (this.$mq.above('400px') || this.$mq.pixelDensity(2)) {
        return 2;
      }
      return 1;
    },
    isDesktop() {
      // call resize for reactivity
      return this.$mq.resize && this.$mq.above('768px');
    },
  },
  methods: {
    /**
     * The image request API was changed in Directus 9, check:
     * https://docs.directus.io/reference/files/#accessing-an-file for more details.
     * Aditionally, many transformastions were added to modify images on the fly,
     * check the available transformations here: https://sharp.pixelplumbing.com/api-operation
     *
     * @param { UUID } filename id of the assets we're accessing.
     * @param { number } widthDesktop
     * @param { number } heightDesktop
     * @param { number } width
     * @param { number } height
     * @param { string } mode
     * @param { array || null } transforms
     *
     * @returns URL
     */
     responsiveCmsImage(
      filename,
      widthDesktop = WIDTH_DESKTOP,
      heightDesktop = HEIGHT_DESKTOP,
      width = WIDTH_MOBILE,
      height = HEIGHT_MOBILE,
      mode = MODE,
      transforms = false,
      format = 'jpg',
      quality = 80,
    ) {
      if (!filename) return undefined;
      const { isDesktop } = this;
      const currentHeight = isDesktop ? heightDesktop : height * this.pixelDensity;
      const currentWidth = isDesktop ? widthDesktop : width * this.pixelDensity;
      const hasTransforms = transforms ? `&transforms=${transforms}` : '';
      const hasFormat = format ? `&format=${format}` : '';

      return `${this.$store.state.cmsBasePath}/assets/${filename}?fit=${mode}&width=${currentWidth}&height=${currentHeight}${hasTransforms}&quality=${quality}${hasFormat}`;
    },
  },
};
