<template>
  <div>
    <div
      v-if="validationErrors.password"
      class="login__error-message landinglayout__error-message"
    >
      {{ validationErrors.password }}
    </div>

    <form
      class="login__auth"
      @submit.prevent="login"
    >
      <app-input
        ref="input"
        v-model="password"
        :input-class="[
          'login__input landinglayout__input', { 'error': validationErrors.password }
        ]"
        :label="getText('password_placeholder', textCollection)"
        :placeholder="getText('password_placeholder', textCollection)"
        type="password"
        autocorrect="off"
        autocapitalize="none"
        autocomplete="current-password"
        @input="validationErrors.password = null"
        @keyup.enter="credentialsLogin"
      />

      <app-button
        :disabled="loggingIn"
        :label="getText('submit_label', textCollection)"
        class="login__button landinglayout__button"
      />
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LoginForm',

  data() {
    return {
      password: '',
      validationErrors: {},
      loggingIn: false,
      textCollection: 'login',
    };
  },
  computed: {
    ...mapState('auth', ['loginMode']),

    returnToRoute() {
      return this.$route.query.returnTo ?? { name: 'Home' };
    },
  },
  methods: {
    validate() {
      this.validationErrors = {};
      if (!this.password) {
        this.validationErrors.password = this.getText('password_missing_error_message', this.textCollection);
      }
      return !this.validationErrors.password;
    },
    async login() {
      if (!this.validate()) return;
      this.$emit('loginStart');
      this.loggingIn = true;

      try {
        this.$refs.input.$refs.input.blur();
        await this.$store.dispatch('auth/default/login', { group: this.password });
        await this.$store.dispatch('auth/user');
        this.$emit('loginSuccess');
      } catch (err) {
        this.$emit('loginError', err);
      } finally {
        this.loggingIn = false;
      }
    },
  },

};
</script>
