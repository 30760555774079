export default (siteId, callback, trackingUrl) => {
  if (!trackingUrl) {
    // eslint-disable-next-line
    console.error("No tracking_url in CMS (adminBasics table)");
    return;
  }
  // eslint-disable-next-line
  const _paq = window._paq = window._paq || [];
  const url = trackingUrl;
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  _paq.push(['setCustomDimension']);
  _paq.push(['setTrackerUrl', `${url}matomo.php`]);
  _paq.push(['setSiteId', siteId]);
  const d = document;
  const g = d.createElement('script');
  const s = d.getElementsByTagName('script')[0];
  g.type = 'text/javascript';
  g.async = true;
  g.src = `${url}matomo.js`;
  g.onload = () => {
    callback();
  };
  s.parentNode.insertBefore(g, s);
};
