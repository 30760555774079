  /*
   * Theme Icons filename override
   *
   * [ICON_KEY]: filename in folder @/assets/themes/[THEME]/icons/
   *
   * override if filename is different from icon key
   * default: @/assets/themes/[THEME]/icons/[ICON_KEY].svg; fallback to default icons
   */

const icons = {
  audi: {
    'arrow-l-light': 'arrow-l',
    'arrow-navi-l': 'arrow-l',
    'arrow-r-light': 'arrow-r',
    'arrow-navi-r': 'arrow-r',
    backLink: 'arrow-l',
    call: 'phone',
    hotline: 'phone',
    journalists: 'download',
    map: 'map',
    map2: 'map',
    training: 'training',
    'resize-increase': 'resize-increase',
  },
  'bmw-group': {
    backLink: 'arrow-l',
    call: 'phone',
    contact: 'profile',
    journalists: 'download',
    mail: 'contact',
  },
  corporate: {
    backLink: 'arrow-l',
    call: 'phone',
    contact: 'profile',
    journalists: 'download',
    mail: 'contact',
  },
  'bmw-mini': {
    'arrow-navi-l': 'arrow-l-light',
    'arrow-navi-r': 'arrow-r-light',
    backLink: 'arrow-l',
    call: 'phone',
  },
  'bmw-i': {
    backLink: 'arrow-l',
    call: 'phone',
  },
  vw: {
    'arrow-navi-l': 'arrow-l',
    'arrow-navi-r': 'arrow-r',
    backLink: 'arrow-l-light',
    didyouknow: 'info',
    hotline: 'phone',
    journalists: 'download',
    map2: 'map',
  },
  default: {
    'arrow-double': 'arrow-double',
    'arrow-l': 'arrow-l',
    'arrow-l-light': 'arrow-l-light',
    'arrow-navi-l': 'arrow-l-light',
    'arrow-navi-r': 'arrow-r-light',
    'arrow-r': 'arrow-r',
    'arrow-r-light': 'arrow-r-light',
    'arrow-scroll-top': 'arrow-scroll-top',
    backLink: 'arrow-l',
    'burger-close': 'burger-close',
    'burger-open': 'burger-open',
    calendar: 'calendar',
    call: 'call',
    car: 'car',
    chat: 'chat',
    close: 'close',
    cloudy: 'cloudy',
    contact: 'contact',
    diary: 'diary',
    didyouknow: 'didyouknow',
    document: 'document',
    download: 'download',
    drop: 'drop',
    facebook: 'social-facebook',
    fog: 'fog',
    hotline: 'phone',
    image: 'image',
    info: 'info',
    instagram: 'social-instagram',
    journalists: 'download',
    mail: 'mail',
    map: 'map2',
    map2: 'map2',
    minus: 'minus',
    partlycloudy: 'partlycloudy',
    phone: 'phone',
    pin: 'pin',
    plus: 'plus',
    rain: 'rain',
    rating: 'rating',
    reset: 'reset',
    resize: 'resize',
    'resize-increase': 'resize',
    routes: 'routes',
    search: 'search',
    snow: 'snow',
    sun: 'sun',
    thunderstorm: 'thunderstorm',
    weather: 'weather',
    youtube: 'social-youtube',
  },
};

export default icons;
