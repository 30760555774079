import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

// Modules
import directus from 'ir-vuex-directus';
import authModule from './authModule';
import phasesModule, { PHASE_CONSTANTS } from './phasesModule';

import { phaseFilter } from '@/helper/phasehelper';

import directusTables from '@/util/directusTables';

import { changeCustomer } from './themeHandler';
import { checkProject } from './projectHelper';

const {
  VUE_APP_API_URL,
} = process.env;

const directusConfig2 = {
  apiUrl: VUE_APP_API_URL,
  init: {
    auth: { mode: process.env.VUE_APP_DIRECTUS_AUTH_MODE || 'cookie' },
  },
  apiVersion: '_',
  filterGroups: true,
  tables: directusTables,
};

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    customer: changeCustomer(),
    cmsBasePath: VUE_APP_API_URL,
    journalistGroupNumber: '',
    selectedGroup: null,
    project: checkProject(),
    feedbackSubmited: false,
    recentContacts: [],
    user: null,
    homeMode: null,
    fullPagesHome: false,
    debug: false,
  },
  plugins: [
    createPersistedState({
      paths: [
        'cms.authenticated',
        'cms.basics',
        'cms.contacts',
        'cms.group',
        'cms.phases',
        'cms.home',
        'cms.home_hybrid',
        'cms.home_hybrid_tiles',
        'cms.home_press_tiles',
        'cms.home_delegate_tiles',
        'cms.schedule',
        'cms.pages',
        'cms.simple_pages',
        'cms.schedule_files',
        'cms.agenda_items',
        'cms.agenda_times',
        'cms.welcome',
        'cms.texts',
        'cms.videos',
        'cms.product_images',
        'cms.contact_tags',
        'cms.survey_structure',
        'journalistGroupNumber',
        'selectedGroup',
        'homeMode',
        'debug',
        'customer',

        'auth.userservice.token',
        'auth.userservice.refreshToken',

        `${PHASE_CONSTANTS.NAME}.${PHASE_CONSTANTS.STATE.CURRENT_PHASE}`,
      ],
    }),
    // tables will be filtered by currentPhase
    // requires many-to-many relation with phases table
    // and following structure: phases.phases_id.name
    phaseFilter({
      paths: [
        'home_hybrid',
        'home_hybrid_tiles',
        'home_press_tiles',
        'product_images',
        'product_images2',
        'presskit_categories',
        'videos',
        'videos2',
        'home',
        'welcome',
      ],
    }),
  ],
  mutations: {
    addRecentContact: (state, contactId) => state.recentContacts.push(contactId),
    setUser: (state, user) => state.user = user,
    setDebug: (state, debugMode) => state.debug = debugMode,
    saveGroupNumber: (state, data) => state.journalistGroupNumber = data,
    setFullPagesHomeMode: (state, fullPagesHome) => state.fullPagesHome = fullPagesHome,
    changeGroup: (state, group) => (state.selectedGroup = group),
    feedbackSubmited: (state, payload) => state.feedbackSubmited = payload,

    setCustomer: (state, customer) => {
      if (state.debug && state.customer) return;
      state.customer = changeCustomer(customer);
    },
    setHomeMode: (state, homeMode) => {
      if (state.debug && state.homeMode) return;
      state.homeMode = homeMode;
    },

    // debug setters
    setCustomerDebug: (state, customer) => state.customer = changeCustomer(customer),
    setHomeModeDebug: (state, homeMode) => state.homeMode = homeMode,
  },

  actions: {
    saveGroupNumber({ commit }, num) {
      commit('saveGroupNumber', num);
    },

    addRecentContact({ commit }, id) {
      commit('addRecentContact', id);
    },
  },

  modules: {
    cms: directus(directusConfig2),
    auth: authModule,
    [PHASE_CONSTANTS.NAME]: phasesModule,
  },
});
