import axios from 'axios';
import store from '@/store';

const {
  VUE_APP_OIDC_SERVICE_URL,
  VUE_APP_API_URL,
} = process.env;

const request = axios.create({
  baseURL: VUE_APP_OIDC_SERVICE_URL,
  credentials: 'include',
  withCredentials: true,
});

const log = (txt, params) => store.state.debug && console.log(`OIDC Service: ${txt}`, params);

export default {
  /**
   * Fetches user data
   *
   * @returns {Promise<void>}
   */
  getUser() {
    log('getUser');
    // TODO login service
    return request.get('/user', { withCredentials: true });
  },

  /**
   * Calls api login url with redirect param to the current app
   *
   * @param { string } returnToRoute url to return to after login
   *
   * @returns {Promise<void>}
   */
  login({ returnToRoute }) {
    log('login', returnToRoute);
    const redirectUrl = encodeURIComponent(window.location.href);
    const cmsLoginUrl = encodeURIComponent(VUE_APP_API_URL);
    const loginUrl = `${request.defaults.baseURL}/login?redirect_url=${redirectUrl}&cms_url=${cmsLoginUrl}`;
    return window.location.href = loginUrl;
  },

  /**
   * Calls api login url with redirect param to the current app
   *
   */
  logout: ({ returnToRoute }) => new Promise((_, reject) => {
    log('logout', returnToRoute);
    const redirectUrl = encodeURIComponent(`${window.location.origin}${returnToRoute ?? ''}`);
    const logoutUrl = `${request.defaults.baseURL}/logout?redirect_url=${redirectUrl}`;
    window.location.href = logoutUrl;
    // reject promise to prevent app route change
    reject();
  }),
};
