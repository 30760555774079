export const createOneTrustCookieScript = (id, document) => {
  const script = document.createElement('script');
  script.setAttribute(
    'src',
    'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
  );
  script.setAttribute('charset', 'UTF-8');
  script.setAttribute('data-domain-script', id);
  document.head.prepend(script);
};

export const createOneTrustCookieScriptConsent = (id, document) => {
  const script = document.createElement('script');
  script.setAttribute(
    'src',
    `https://cdn.cookielaw.org/consent/${id}/OtAutoBlock.js`,
  );
  script.type = 'text/javascript';
  document.head.prepend(script);
};

export const parseCookies = () => {
  const splitCookies = document.cookie.split(';');
  const cookiesObject = splitCookies.reduce((acc, cookie) => {
    const splitKeys = cookie.split('=');
    const normalizeKeys = splitKeys[0].replace('"', '_').trim();
    return {
      ...acc,
      [normalizeKeys]: splitKeys[1],
    };
  }, {});
  return cookiesObject;
};

export const CONSENT_TYPES = {
  ONETRUST: 'ONETRUST',
  COOKIEBOT: 'COOKIEBOT',
  LEGACY: 'LEGACY',
};
