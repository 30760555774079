<template>
  <app-button
    :label="getText('submit_label', 'login')"
    class="login__button landinglayout__button"
    :callback="() => login()"
  />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Login',

  computed: {
    ...mapGetters('auth', ['authenticated']),

    returnToRoute() {
      return this.$route.query.returnTo ?? { name: 'Home' };
    },
  },

  mounted() {
    if (!this.authenticated) {
      this.$store.dispatch('auth/oidc/loginInit', { returnToRoute: this.returnToRoute });
    }
  },

  methods: {
    async login() {
      try {
        await this.$store.dispatch('auth/login', { returnToRoute: this.returnToRoute });
      } catch (err) {
        this.$emit('loginError', err);
      }
    },
  },
};
</script>
