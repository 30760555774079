<template>
  <div class="subpage">
    <PageHeaderText
      :header-text="headerText"
      :image="responsiveCmsImage(headerImage)"
      margin
    />
    <div class="subpage__content">
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  name: 'Subpage',
  props: {
    headerText: {
      type: String,
      default: 'Subpage',
    },
    headerImage: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
@import 'src/components/common/subpage/subpage';
</style>
