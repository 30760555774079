export default {
  'app-container-width': '85rem',

  'body-base-color': '#FFFFFF',
  'body-background-color': '#000000',

  'font-family--header': '"LOUIS-GLOBAL2-BOLD", sans-serif',
  'font-family--interactive': '"LOUIS-GLOBAL2-BOLD", sans-serif',
  'font-family--text-link': '"LOUIS-GLOBAL2-REGULAR", sans-serif',
  'font-family--bold': '"LOUIS-GLOBAL2-BOLD", sans-serif',
  'font-family--body': '"LOUIS-GLOBAL2-REGULAR", sans-serif',
  'font-family--light': '"LOUIS-GLOBAL2-REGULAR", sans-serif',

  'header-text-transform': 'normal',
  'header-background-color': '#000000',
  'header-font-color': '#FFF',

  'header-height': '100px',
  'footer-height': '320px',
  'header-height--medium': '120px',
  'footer-height--medium': '350px',

  'card-background-color': '#000',
  'card-font-color': '#FFF',

  'app-button-min-width': '100%',
  'app-button-min-width--medium': 'none',
  'app-button-padding': '7px 51px',
  'app-button-text-transform': 'none',
  'app-button-min-height': '45px',
  'app-button-align': 'center',
  'app-button--outline-bg-color': 'transparent',
  'app-button--outline-color': '#FFFFFF',
  'app-button--outline-bg-color--hover': '#b6862d',
  'app-button--outline-color--hover': '#FFFFFF',
  'app-button--outline-border-width': '2px',
  'app-button--outline-border-color': '#b6862d',
  'app-button--outline-border-color--hover': '#b6862d',

  'app-page-footer-background-color': '#393839',
  'app-page-footer-link-color': '#fff',
  'app-page-footer-color': '#fff',

  'link-color': '#fff',

  'body-base-font-size': '14px',
  'body-base-font-size--medium': '14px',
  'body-base-line-height': '1.125',
  'body-base-line-height--medium': '1.125',

  'rating-inactive': '#c4d0cd',
  'rating-active': '#000',

  'input-font-size': '1rem',
  'input-background': '#fefefe',
  'input-height': '50px',
  'input-color': '#fff',
  'input-border-color': '#fff',
  'input-border-color--focus': '#000000',
  'input-placeholder-color': '#fff',
  'input-placeholder-text-transform': 'none',
  'input-padding': '0.5rem',
  'select-option-color': '#000000',
  'select-option-color--hover': '#ffffff',
  'select-option-background': '#ffffff',
  'select-option-background--hover': '#000000',

  'page-header-height': 'auto',
  'page-header-height--medium': 'auto',
  'page-header-background-color': 'transparent',
  'page-header-color': '#fff',
};
