<template lang="html">
  <footer
    :class="`footer__container footer footer--${$route.meta.type || ''}`"
  >
    <div class="footer__inner">
      <img
        src="@/assets/img/footer/background-footer.png"
        srcset="@/assets/img/footer/background-footer@2x.png 2x,
        @/assets/img/footer/background-footer@3x.png 3x"
        class="footer__bg"
      >

      <div
        v-if="footerLogo"
        :class="{ 'footer__logo--link': footerLogoLink }"
        class="footer__logo"
      >
        <img
          :src="footerLogo"
          :alt="footerCopyrightText"
          @click="footerLogoLink && handleLinkClick(footerLogoLink)"
        >
      </div>
      <div
        v-if="footerCopyrightText"
        class="footer__copyright"
      >
        <div>
          {{ footerCopyrightText }}
        </div>
      </div>
      <div
        v-if="footerCopyrightLinks"
        class="footer__copyright-links"
      >
        <div class="footer__link-container">
          <span
            v-if="footerCopyrightLinksHeader"
            class="footer__nav-item footer__nav-item--header"
          >
            {{ footerCopyrightLinksHeader }}</span>
          <span
            v-for="(link, idx) in footerCopyrightLinks"
            :key="idx"
            class="footer__nav-item"
            @click="handleLinkClick(link.url)"
          >
            {{ link.label }}
          </span>
        </div>
      </div>
      <div class="footer__links">
        <div class="footer__link-container">
          <span
            v-if="footerLinksHeader"
            class="footer__nav-item footer__nav-item--header"
          >{{ footerLinksHeader }}</span>
          <a
            v-if="showOnetrustCookieBtn"
            class="footer__nav-item ot-sdk-show-settings"
          >
            Cookie Einstellungen
          </a>
          <span
            v-for="(link, idx) in footerLinks"
            :key="idx"
            class="footer__nav-item"
            @click="handleLinkClick(link.url)"
          >
            {{ link.label }}
          </span>
          <span
            v-if="authenticated"
            class="footer__nav-item"
            @click="logout()"
          >
            {{ getText('footer_signout') }}
          </span>
        </div>
      </div>
      <div
        v-if="footerSocialLinks && footerSocialLinks.length > 0"
        class="footer__social-links"
      >
        <span
          v-if="footerSocialLinksHeader"
          class="footer__nav-icon-item footer__nav-icon-item--header"
        >
          {{ footerSocialLinksHeader }}</span>
        <div class="footer__social-link-container">
          <a
            v-for="(link, idx) in footerSocialLinks"
            :key="idx"
            :href="link.url"
            class="footer__nav-icon-item"
            target="_blank"
          >
            <theme-icon
              :image="link.icon_type"
              color="var(--app-page-footer-color)"
              class="footer__nav-icon-item-svg"
            />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import skinner from '@/components/mixins/skinner';

export default {
  name: 'Footer',

  mixins: [skinner],

  props: {
    showOnetrustCookieBtn: {
      type: Boolean,
    },
  },

  computed: {
    footerLogo() {
      return this.getThemeLogos.footer;
    },
    footerLogoLink() {
      return this.getProp('basics', 'footer_logo_link');
    },
    footerLinksHeader() {
      return this.getProp('basics', 'footer_links_header');
    },
    footerLinks() {
      return this.getProp('basics', 'footer_links');
    },
    footerCopyrightLinksHeader() {
      return this.getProp('basics', 'footer_copyright_links_header');
    },
    footerCopyrightLinks() {
      return this.getProp('basics', 'footer_copyright_links');
    },
    footerSocialLinksHeader() {
      return this.getProp('basics', 'footer_social_links_header');
    },
    footerSocialLinks() {
      return this.getProp('basics', 'footer_social_links');
    },
    footerCopyrightText() {
      return this.getText('footer_copyright');
    },
  },
};

</script>

<style lang="scss">
@import 'src/assets/scss/abstracts/_mixins';

@import 'src/components/common/footer/footer';

// Themes
@import 'src/components/common/footer/audi';
@import 'src/components/common/footer/ava';
@import 'src/components/common/footer/bmw-group';
@import 'src/components/common/footer/bmw-car';
@import 'src/components/common/footer/bmw-i';
@import 'src/components/common/footer/bmw-m';
@import 'src/components/common/footer/bmw-mini';
@import 'src/components/common/footer/bmw-motorrad';
@import 'src/components/common/footer/corporate';
@import 'src/components/common/footer/corvette';
@import 'src/components/common/footer/hyu-standard';
@import 'src/components/common/footer/iqos';
@import 'src/components/common/footer/vw';
</style>
