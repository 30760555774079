<template>
  <component
    :is="href ? 'a' : 'button'"
    v-if="!iconSquared"
    :href="href"
    :target="target"
    :disabled="disabled"
    :class="{ 'app-button--icon-only': !label,
              'app-button--full': full,
              'app-button--icon': icon && label,
              'app-button--internal': internal,
              'app-button--primary': primary,
              'app-button--outline': outline,
              'app-button--secondary': secondary,
              'invert': invert } "
    class="app-button"
    @click="clickHandler"
  >
    <theme-icon
      :image="buttonIcon"
      :class="['icon-svg', iconClass]"
      :color="iconColor"
    />
    <span
      v-if="label"
      :class="labelClass"
    >
      <span
        v-sane-html="label"
        class="app-button__label-inner"
      />
    </span>
  </component>
  <component
    :is="href ? 'a' : 'button'"
    v-else
    :href="href"
    :target="target"
    :disabled="disabled"
    class="app-button-icon-squared__wrapper"
    @click="clickHandler"
  >
    <div
      :class="{ 'app-button--primary': primary,
                'app-button--secondary': secondary,
              }"
      class="app-button app-button--icon-only"
    >
      <theme-icon
        :image="buttonIcon"
        :class="['icon-svg', iconClass]"
        :color="iconColor"
      />
    </div>
    <span
      v-sane-html="label"
      class="app-button__label"
    />
  </component>
</template>

<script>
import skinner from '@/components/mixins/skinner';

export default {
  name: 'Button',
  mixins: [skinner],
  props: {
    label: {
      type: String,
      default: () => '',
    },
    href: {
      type: String,
      default: undefined,
    },
    download: {
      type: String,
      default: '',
    },
    downloadType: {
      type: String,
      default: 'image/jpg',
    },
    icon: {
      type: String,
      default: () => null,
    },
    iconSquared: {
      type: Boolean,
      default: false,
    },
    iconClass: {
      type: String,
      default: () => 'theme-icon',
    },
    labelClass: {
      type: String,
      default: 'app-button__label',
    },
    full: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    internal: {
      type: Boolean,
      default: false,
    },
    priority: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    invert: {
      type: Boolean,
      default: false,
    },
    callback: {
      type: Function,
      default: () => {},
    },
    trackingType: {
      type: String,
      default: () => 'LinkOutbound',
    },
    iconColor: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    target() {
      return this.isExternalLink(this.href) ? '_blank' : null;
    },
    buttonIcon() {
      return this.icon || '';
    },
  },
  methods: {
    clickHandler() {
      if (this.href) this.trackOutboundLink(this.href, this.$route.path, this.trackingType);
      if (this.download) this.forceDownload(this.download);
      if (this.callback) this.callback();
    },
    async forceDownload(url) {
      if (!url) return;
      const filename = url.split('/').pop();

      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';

      xhr.onload = () => {
        this.isDownload = false;
        if (xhr.status !== 200) return;
        if (window.navigator.msSaveOrOpenBlob) {
          const blob = new Blob([xhr.response], { type: this.downloadType });
          window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(xhr.response);
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      };
      xhr.send();
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/abstracts/_mixins';
@import 'src/assets/scss/abstracts/_variables';

@import 'src/components/common/button/button';

// Themes
@import 'src/components/common/button/audi';
@import 'src/components/common/button/ava';
@import 'src/components/common/button/bmw-car';
@import 'src/components/common/button/bmw-i';
@import 'src/components/common/button/bmw-group';
@import 'src/components/common/button/bmw-mini';
@import 'src/components/common/button/bmw-motorrad';
@import 'src/components/common/button/corporate';
@import 'src/components/common/button/hyu-standard';
@import 'src/components/common/button/iqos';
@import 'src/components/common/button/vw';
</style>
