export default [
  {
    name: 'directus_roles',
    preload: true,
    params: { fields: 'name' },
    published: false,
  },
  {
    name: 'home', params: { fields: '*.*.*.*' },
  },
  {
    name: 'welcome', preload: true, group: true, params: { fields: '*.*' },
  },
  {
    name: 'pages', preload: true, params: { fields: '*.*' },
  },
  {
    name: 'simple_pages', preload: true, params: { fields: '*.*' },
  },
  {
    name: 'texts', preload: true, params: { fields: '*.*' },
  },
  {
    name: 'basics', preload: true, params: { fields: '*.*' },
  },
  {
    name: 'weathertile', preload: true, params: { fields: '*.*' },
  },
  {
    name: 'phases', preload: true, params: { fields: '*.*' },
  },
  {
    name: 'home_press_tiles', group: true, params: { fields: '*.*.*.*' },
  },
  {
    name: 'home_hybrid', preload: true, params: { fields: '*.*' },
  },
  {
    name: 'home_hybrid_tiles', preload: true, params: { fields: '*.*.*.*' },
  },
  {
    name: 'image_pages', preload: true, params: { fields: '*.*.*.*' },
  },
  {
    name: 'navigation_links', preload: true, params: { fields: '*.*' },
  },
  {
    name: 'home_delegate_tiles', params: { fields: '*.*.*.*' },
  },
  {
    name: 'diary', params: { fields: '*.*.*' },
  },
  {
    name: 'videos', params: { fields: '*.*' },
  },
  {
    name: 'survey_structure', params: { fields: '*.*' },
  },
  {
    name: 'agenda_items', params: { fields: '*.*' },
  },
  {
    name: 'agenda_times', params: { fields: '*.*' },
  },
  {
    name: 'admin_basics', params: { fields: '*.*.*.*' },
  },
  {
    name: 'login', params: { fields: '*.*' },
  },
  {
    name: 'registration', params: { fields: '*.*' },
  },
  {
    name: 'newsletter', params: { fields: '*.*' },
  },
  {
    name: 'contacts', params: { fields: '*,roles.directus_roles_id.name,tags.tag.tag' },
  },
  {
    name: 'contact_tags', params: { fields: '*.*', sort: 'tag' },
  },
  {
    name: 'infos', params: { fields: '*.*' },
  },
  {
    name: 'schedule', params: { fields: '*.*.*' },
  },
  {
    name: 'schedule_files', params: { fields: '*.*.*' },
  },
  {
    name: 'location', params: { fields: '*.*' },
  },
  {
    name: 'routes', params: { fields: '*.*' },
  },
  {
    name: 'journalist_list', params: { fields: '*.*' },
  },
  {
    name: 'internal_documents', params: { fields: '*.*' },
  },
  {
    name: 'weather', params: { fields: '*.*' },
  },
  {
    name: 'floorplans', params: { fields: '*.*' },
  },
  {
    name: 'content_hub', params: { fields: '*.*' },
  },
  {
    name: 'did_you_know', params: { fields: '*.*' },
  },
  {
    name: 'presskit_categories', params: { fields: '*.*' },
  },
  {
    name: 'product_images', params: { fields: '*.*' },
  },
  {
    name: 'sneak_peek', params: { fields: '*.*' },
  },
  {
    name: 'themes', params: { fields: '*.*' },
  },
  {
    name: 'modals', params: { fields: '*.*' },
  },
  {
    name: 'demo_content', params: { fields: '*.*.*' },
  },
];
