import safeGet from 'lodash/get';

import store from '@/store';

import service from './service';

import { ApiErrors } from '@/util/errors';

const log = (txt, params) => store.state.debug && console.log(`USERSERVICE Auth Module: ${txt}`, params);

export default {
  namespaced: true,

  state: {
    token: false,
    refreshToken: false,
  },

  mutations: {
    setToken: (state, token) => state.token = token,
    setRefreshToken: (state, refreshToken) => state.refreshToken = refreshToken,
  },

  actions: {
    /**
     * Calls service login action
     * - commits token
     * - commits refresh token
     * - commits auth module user
     * - dispatches user action
     * - dispatches directus login action
     *
     * @param { string } group url to return to after login
     *
     * @returns {Promise<void>}
     */
    async login({ commit, dispatch }, { returnToRoute, credentials }) {
      log('login', returnToRoute);
      try {
        const response = await service.login({ returnToRoute, credentials });
        const {
          token: { accessToken, refreshToken },
          user,
          group = safeGet(user, 'userGroups[0].password'),
        } = response.data;

        commit('setToken', accessToken);
        commit('setRefreshToken', refreshToken);
        commit('auth/setUser', user, { root: true });

        await dispatch('user');
        return dispatch('auth/directusLogin', { group }, { root: true });
      } catch (err) {
        const { status, data } = safeGet(err, 'response');
        if (status === 401) {
          const message = (data.message || '').toString();
          if (message.includes('exceeded')) throw new Error(ApiErrors.PASSWORD_ATTEMPTS_EXCEEDED);
        }
        throw new Error(err);
      }
    },

    /**
     * Calls service user data action
     *
     * @returns {Promise<User>} user data
     */
    async user({ state }) {
      log('get user', state.token);
      const { data } = await service.getUser({ token: state.token });
      return data;
    },

    /**
     * Calls service refresh token action
     *
     * @returns {Promise<void>}
     */
    async refresh({ state, commit }) {
      log('refresh token', state.refreshToken);
      const { data } = await service.refreshToken({ refreshToken: state.refreshToken });
      commit('setToken', data.AccessToken);
    },

    /**
     * Calls password policies action
     *
     * @returns {Promise<passwordPolicies>}
     */
    async getPasswordPolicies() {
      const { data } = await service.passwordPolicies();
      return data;
    },

    /**
     * Deletes access token / refresh token
     *
     * @returns {Promise<void>}
     */
    async logout({ commit }) {
      log('logout');
      commit('setRefreshToken', false);
      commit('setToken', false);
    },

    /**
     * Calls service register
     *
     * @param {*} formData
     *
     * @returns {Promise<void>}
     */
    async register(_, formData) {
      try {
        await service.register({ formData });
      } catch (err) {
        switch (safeGet(err, 'response.status')) {
          case 409: throw new Error(ApiErrors.CONFLICT);
          case 423: throw new Error(ApiErrors.LOCKED);
          default: throw new Error(err);
        }
      }
    },

    /**
     * Calls service confirm registration
     *
     * @param {string} email
     * @param {string} verificationCode
     *
     * @returns {Promise<void>}
     */
    async confirmRegistration(_, { email, verificationCode }) {
      await service.confirmRegistration({ email, verificationCode });
    },

    /**
     * Calls service password reset
     *
     * @param {string} email
     * @param {string} password
     * @param {string} verificationCode
     * @param {string} resetCode
     *
     * @returns {Promise<void>}
     */
    async resetPassword(_, {
      email, password, verificationCode, resetCode,
    }) {
      try {
        if (resetCode) await service.setPassword({ email, password, resetCode });
        else await service.resetPassword({ email, password, verificationCode });
      } catch (err) {
        const { status, data } = safeGet(err, 'response');
        if (status === 400) {
          const message = (data.message || '').toString().toLowerCase();
          if (message.includes('user')) throw new Error(ApiErrors.USER_NOT_FOUND);
          else if (message.includes('uuid')) throw new Error(ApiErrors.CODE_INVALID);
          else if (message.includes('invalid')) throw new Error(ApiErrors.CODE_INVALID);
          else if (message.includes('used')) throw new Error(ApiErrors.CODE_ALREADY_USED);
          else if (message.includes('exceeded')) throw new Error(ApiErrors.PASSWORD_ATTEMPTS_EXCEEDED);
        }

        throw new Error(err);
      }
    },

    /**
     * Calls service request password
     *
     * @param {string} email
     *
     * @returns {Promise<void>}
     */
    async requestPasswordReset(_, { email }) {
      await service.requestPasswordReset({ email });
    },
  },
};
