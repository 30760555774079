<template>
  <div :class="[ getSkinClass ]">
    <debug v-if="debug" />

    <phase-debug v-if="phaseDebug && !debug" />

    <div
      id="app"
      class="app"
    >
      <browser-warning v-if="showBrowserWarning" />
      <CommonHeader v-if="showHeader" />
      <router-view :key="$route.fullPath" />
      <CommonFooter
        v-if="showFooter"
        :show-onetrust-cookie-btn="showOnetrustCookieBtn"
      />
      <ButtonScrollToTop />

    </div>

    <modal-pip />

    <component
      :is="cookieConsentComponent"
      v-if="cookieConsentComponent && admin_basics"
    />
  </div>
</template>

<script>
import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';

import { mapState, mapGetters, mapMutations } from 'vuex';

// Mixins
import skinner from '@/components/mixins/skinner';
import { phaseUpdater, phaseMixin } from '@/helper/phasehelper';

import AppCookieBar from '@/components/common/cookiebar/CookieBar';
import ButtonScrollToTop from '@/components/common/scrolltotop/ScrollToTop';
import BrowserWarning from '@/components/common/browserwarning/BrowserWarning';
import CommonFooter from '@/components/common/footer/Footer';
import CommonHeader from '@/components/common/header/Header';
import CookiebotBanner from '@/components/common/cookiebot/CookiebotBanner';
import Debug from '@/components/common/debug/Debug';
import PhaseDebug from '@/components/common/debug/PhaseDebug';

import { LandingModes } from '@/util/landingModes';

import cookieconsentMixin from '@/helper/cookieconsent';

// constants
import { CONSENT_TYPES } from './util/cookies';
import { PHASE_CONSTANTS } from '@/store/phasesModule';

import { changeCustomer } from './store/themeHandler';

const { VUE_APP_BASE_THEME } = process.env;

export default {
  name: 'App',

  components: {
    Debug,
    PhaseDebug,
    CommonHeader,
    CommonFooter,
    ButtonScrollToTop,
    BrowserWarning,
    AppCookieBar,
    CookiebotBanner,
  },
  mixins: [
    skinner,
    phaseUpdater,
    phaseMixin,
    cookieconsentMixin,
  ],
  data() {
    return {
      isTouchDevice: false,
      lastTouchY: 0,
      debugMode: false,
      gaDev: 'UA-99003244-17',
    };
  },
  computed: {
    ...mapState(['debug', 'customer']),
    ...mapState('cms', ['basics', 'admin_basics']),
    ...mapState('cms', {
      cmsAuthenticated: state => state.authenticated,
    }),
    ...mapGetters(PHASE_CONSTANTS.NAME, {
      landingMode: PHASE_CONSTANTS.GETTERS.LANDING_MODE,
    }),
    ...mapGetters('auth', ['authenticated']),

    phaseDebug() {
      return this.getProp('basics', 'show_phase_switcher');
    },

    showFooter() {
      return !this.$route.meta.hideFooter;
    },

    showHeader() {
      return !this.$route.meta.hideHeader;
    },

    showBrowserWarning() {
      return this.isIE && this.$route.meta.type === 'landing';
    },
    isIE() {
      return /MSIE|Trident/.test(window.navigator.userAgent);
    },

    cookieConsentComponent() {
      // cookieconsentMixin
      if (this.cookieConsentType === CONSENT_TYPES.ONETRUST) return null;
      if (this.cookieConsentType === CONSENT_TYPES.COOKIEBOT) return CookiebotBanner;
      return AppCookieBar;
    },

    showOnetrustCookieBtn() {
      return this.cookieConsentType === CONSENT_TYPES.ONETRUST;
    },
  },
  watch: {
    basics: function (basics) {
      this.setBasics(basics);
    },
    admin_basics: function (adminBasics) {
      this.setAdminBasics(adminBasics);
    },
    getThemeLogos: {
      immediate: true,
      handler({ favicon }) {
        this.setFavicon(favicon);
      },
    },
    '$route.query.debug': {
      immediate: true,
      handler(debug) {
        if (!debug) return;
        this.$store.commit('setDebug', debug);
      },
    },
    authenticated: {
      immediate: true,
      handler(val) {
        if (!val) return;
        this.$store.dispatch('cms/preloadData');
      },
    },
    cmsGroup: {
      handler(val) {
        if (!val) return;
        this.$tracking.set('dimension1', val);
      },
    },
    cmsAuthenticated: {
      handler(val, old) {
        if (old && !val) this.logout();
      },
    },
    landingMode: {
      handler(newVal) {
        if (newVal === LandingModes.LANDING_MODE_NONE && this.$route.meta.type === 'landing') {
          // go to root if on login
          this.$router.push({ path: '/' });
        } else if (this.$route.meta.auth && !this.authenticated
          // go to landing if on protected route and auth mode
          && newVal !== LandingModes.LANDING_MODE_NONE) {
          this.goToLanding();
        }
      },
    },
  },
  async mounted() {
    if (VUE_APP_BASE_THEME) this.$store.commit('setCustomer', VUE_APP_BASE_THEME);
    changeCustomer(this.customer.name);

    // needed for fix scroll behaviour on windos phone
    if (navigator.userAgent.match(/Windows Phone/i)) {
      document.addEventListener('touchstart', this.touchstartHandler, false);
      document.addEventListener('touchmove', this.touchmoveHandler, false);
    }

    const isTouchDevice = 'ontouchstart' in document.documentElement;
    if (isTouchDevice) document.body.classList.add('touch');

    this.setBasics(this.basics);
    this.$store.dispatch('cms/getItems', { tableName: 'admin_basics', params: { fields: '*.*' } });
    this.$store.dispatch('cms/getItems', { tableName: 'login', params: { fields: '*.*' } });
    this.$store.dispatch('cms/getItems', { tableName: 'registration', params: { fields: '*.*' } });
    this.$store.dispatch('cms/getItems', { tableName: 'basics', params: { fields: '*.*' } });
    this.$store.dispatch('cms/getItems', { tableName: 'phases', params: { fields: '*.*' } });
    this.$store.dispatch('cms/getItems', { tableName: 'simple_pages', params: { fields: '*.*' } });
    this.$store.dispatch('cms/getItems', { tableName: 'pages', params: { fields: '*.*' } });
    this.$store.dispatch('cms/getItems', { tableName: 'texts', params: { fields: '*.*' } });
  },
  methods: {
    ...mapMutations(['setDebug']),

    setAdminBasics(adminBasics) {
      this.$store.commit('setCustomer', adminBasics.theme.name);
      this.$store.commit('setHomeMode', adminBasics.hub_mode || 'press');
    },
    setFavicon(favicon) {
      const link = document.createElement('link');
      const lastLink = document.querySelector("link[rel*='icon']");
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = favicon;
      if (lastLink) document.head.removeChild(lastLink);
      document.getElementsByTagName('head')[0].appendChild(link);
    },
    setBasics(basics) {
      if (isEmpty(basics)) return; // basics is no longer an array.
      document.title = basics.title;
      // Setting timezone for cms dates (as directus stores dates without utc offset)
      if (basics.timezone) moment.tz.setDefault(basics.timezone);
    },
    touchstartHandler(e) {
      if (e.touches.length !== 1) {
        return;
      }
      this.lastTouchY = e.touches[0].clientY;
    },
    touchmoveHandler(e) {
      const touchY = e.touches[0].clientY;
      const touchYDelta = touchY - this.lastTouchY;
      this.lastTouchY = touchY;
      if (
        (touchYDelta > 0 && document.documentElement.scrollTop - touchYDelta < 0)
        || window.pageYOffset < 0
      ) {
        e.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/_app.scss';
</style>
