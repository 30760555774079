<template>
  <div>
    <div
      v-if="validationErrors.email"
      class="login__error-message landinglayout__error-message"
    >
      {{ validationErrors.email }}
    </div>

    <div
      v-if="validationErrors.password"
      class="login__error-message landinglayout__error-message"
    >
      {{ validationErrors.password }}
    </div>

    <form
      class="login__auth"
      @submit.prevent="credentialsLogin"
    >
      <app-input
        ref="input"
        v-model="email"
        :input-class="[
          'login__input landinglayout__input', { 'error': validationErrors.email }
        ]"
        :label="getText('email_placeholder', textCollection)"
        :placeholder="getText('email_placeholder', textCollection)"
        type="text"
        autocorrect="off"
        autocapitalize="none"
        autocomplete="email"
        @input="validationErrors.email = null"
        @keyup.enter="credentialsLogin"
      />

      <app-input
        ref="input"
        v-model="password"
        :input-class="[
          'login__input landinglayout__input', { 'error': validationErrors.password }
        ]"
        :label="getText('password_placeholder', textCollection)"
        :placeholder="getText('password_placeholder', textCollection)"
        type="password"
        autocorrect="off"
        autocapitalize="none"
        autocomplete="current-password"
        @input="validationErrors.password = null"
        @keyup.enter="credentialsLogin"
      />

      <div class="login__links landinglayout__links">
        <router-link
          :to="{ name: 'RequestPasswordReset' }"
          class="login__link landinglayout__link"
        >
          {{ loginTexts.password_reset_link_label }}
        </router-link>
      </div>

      <app-button
        :disabled="loggingIn"
        :label="getText('submit_label', textCollection)"
        class="login__button landinglayout__button"
      />
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LoginForm',

  data() {
    return {
      email: '',
      password: '',
      validationErrors: {},
      loggingIn: false,
      textCollection: 'login',
    };
  },
  computed: {
    ...mapState('auth', ['loginMode']),

    loginTexts() {
      return this.getProp('login', 'translations[0]', {});
    },

    returnToRoute() {
      return this.$route.query.returnTo ?? { name: 'Home' };
    },
  },
  methods: {
    validate() {
      this.validationErrors = {};
      if (!this.email) {
        this.validationErrors.email = this.getText('email_missing_error_message', this.textCollection);
      }
      if (!this.password) {
        this.validationErrors.password = this.getText('password_missing_error_message', this.textCollection);
      }
      return !this.validationErrors.email && !this.validationErrors.password;
    },
    credentialsLogin() {
      this.login({
        email: this.email.trim(),
        password: this.password,
      });
    },
    async login(credentials) {
      if (!this.validate()) return;
      this.$emit('loginStart');
      this.loggingIn = true;
      try {
        this.$refs.input.$refs.input.blur();
        await this.$store.dispatch('auth/userservice/login', { credentials });
        this.$emit('loginSuccess');
      } catch (err) {
        this.$emit('loginError', err);
      } finally {
        this.loggingIn = false;
      }
    },
  },

};
</script>
