export default {
  // global
  'app-container-width': '80rem',

  'body-base-color': '#262626',
  'body-background-color': '#FFF',

  'font-family--header': '"ChronicleDisplay-XLight", sans-serif',
  'font-family--interactive': '"NeuzeitOfficePro-Regular", sans-serif',
  'font-family--text-link': '"NeuzeitOfficePro-Regular", sans-serif',
  'font-family--bold': '"NeuzeitOfficePro-Regular", sans-serif',
  'font-family--body': '"NeuzeitOfficePro-Regular", sans-serif',
  'font-family--light': '"NeuzeitOfficePro-Regular", sans-serif',

  'header-text-transform': 'normal',

  'body-base-font-size': '18px',
  'body-base-font-size--medium': '18px',
  'body-base-line-height': '1.5',
  'body-base-line-height--medium': '1.5',

  'app-button-border-width': '1px', // keep the 'px' unit for calculations

  'app-button-bg-color--hover': '#000000',
  'app-button-bg-color': '#FFFFFF',
  'app-button-border-color--hover': '#000000',
  'app-button-border-color': '#000000',
  'app-button-color--hover': '#fff',
  'app-button-color': '#000000',
  'app-button-disabled-bg-color': '#000000',
  'app-button-disabled-color': '#fff',
  'app-button-font-size': 'inherit',
  'app-button-line-height': '1',

  'app-button--primary-bg-color--hover': '#FFFFFF',
  'app-button--primary-bg-color': '#000000',
  'app-button--primary-border-color--hover': '#000000',
  'app-button--primary-border-color': '#000000',
  'app-button--primary-color--hover': '#000000',
  'app-button--primary-color': '#FFFFFF',
  'app-button--primary-disabled-bg-color': '#000000',
  'app-button--primary-disabled-color': '#FFFFFF',

  'app-button--secondary-bg-color--hover': '#000000',
  'app-button--secondary-bg-color': '#FFFFFF',
  'app-button--secondary-border-color--hover': '#000000',
  'app-button--secondary-border-color': '#000000',
  'app-button--secondary-color--hover': '#fff',
  'app-button--secondary-color': '#000000',
  'app-button--secondary-disabled-bg-color': '#000000',
  'app-button--secondary-disabled-color': '#fff',

  'app-button--outline-bg-color--hover': '#FFFFFF',
  'app-button--outline-bg-color': 'transparent',
  'app-button--outline-border-color--hover': '#FFFFFF',
  'app-button--outline-border-color': '#FFFFFF',
  'app-button--outline-border-width': '1px',
  'app-button--outline-color--hover': '#000000',
  'app-button--outline-color': '#FFFFFF',

  'app-button-align': 'center',
  'app-button-color-squared': '#000000',
  'app-button-min-height': '45px',
  'app-button-min-width--medium': 'none',
  'app-button-min-width': '100%',
  'app-button-padding': '0 42px',
  'app-button-text-transform': 'none',

  'input-font-size': '1rem',
  'input-background': 'transparent',
  'input-height': '35px',
  'input-color': '#0a0a0a',
  'input-border-color': 'black',
  'input-border-color--focus': 'black',
  'input-placeholder-color': '#bbbbbb',
  'input-placeholder-text-transform': 'none',
  'input-padding': '0.5rem',
  'select-option-color': '#000',
  'select-option-color--hover': '#000',
  'select-option-background': '#ffffff',
  'select-option-background--hover': '#eeeeee',

  'app-page-footer-background-color': '#FFF',
  'app-page-footer-link-color': '#000',
  'app-page-footer-color': '#000',

  'header-height': '100px',
  'footer-height': '320px',
  'header-height--medium': '120px',
  'footer-height--medium': '450px',
  'header-background-color': '#FFFFFF',

  'home-tile-border-color': '#000',
  'home-tile-border-width': '0px',
  'home-tile-icons-visibility': 'hidden',
  'home-tile-height': '135px',
  'home-tile-height--medium': '240px',

  'page-header-height': 'auto',
  'page-header-height--medium': 'auto',
  'page-header-background-color': 'transparent',
  'page-header-color': '#000000',

  'contact-card-background': 'transparent',

};
