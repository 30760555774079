import { mapState } from 'vuex';

import {
  createOneTrustCookieScript,
  createOneTrustCookieScriptConsent,
  parseCookies,
  CONSENT_TYPES,
} from '@/util/cookies';

const {
  VUE_APP_COOKIEBOT_URL,
  VUE_APP_ONETRUST_COOKIE_ID,
} = process.env;

export default {

  data: () => ({
    checkTechnicalCookiesIntervalId: null,
    isTechnicalCookieAccepted: false,
    isAdvCookieAccepted: false,
  }),

  computed: {
    ...mapState('cms', ['admin_basics']),

    cookieConsentType() {
      if (VUE_APP_ONETRUST_COOKIE_ID) return CONSENT_TYPES.ONETRUST;
      if (VUE_APP_COOKIEBOT_URL
        && this.admin_basics?.cookiebot_id
        && this.isProdEnv
      ) return CONSENT_TYPES.COOKIEBOT;
      return CONSENT_TYPES.LEGACY;
    },
  },

  mounted() {
    if (this.cookieConsentType === CONSENT_TYPES.ONETRUST) this.oneTrustSetup();
  },

  methods: {
    createOneTrustScripts() {
      if (VUE_APP_ONETRUST_COOKIE_ID) {
        createOneTrustCookieScript(VUE_APP_ONETRUST_COOKIE_ID, document);
        createOneTrustCookieScriptConsent(VUE_APP_ONETRUST_COOKIE_ID, document);
      }
    },

    oneTrustSetup() {
      this.createOneTrustScripts();

      this.checkTechnicalCookiesIntervalId = window.setInterval(
        () => this.checkTechnicalCookies(),
        2000,
      );
    },

    checkTechnicalCookies() {
      const {
        dw_Advertisement_cookie: advertisementCookieValue,
        dw_Technical_cookie: technicalCookieValue,
      } = parseCookies();

      if (advertisementCookieValue === 'opt-in' && !this.isAdvCookieAccepted) {
        if (window.dataLayer) window.dataLayer.push({ event: 'consentoptin' });
        this.isAdvCookieAccepted = true;
      }
      if (advertisementCookieValue === 'opt-out' && this.isAdvCookieAccepted) {
        this.isAdvCookieAccepted = false;
      }

      if (technicalCookieValue === 'opt-in' && !this.isTechnicalCookieAccepted) {
        // if (this.$gtag) this.$gtag.optIn();
        // if (this.$matomo) {
        //   this.$matomo.forgetUserOptOut();
        //   this.$matomo.rememberConsentGiven();
        // }
        this.isTechnicalCookieAccepted = true;
      }
      if (technicalCookieValue === 'opt-out' && this.isTechnicalCookieAccepted) {
        this.isTechnicalCookieAccepted = false;
        // if (this.$gtag) this.$gtag.optOut();
        // if (this.$matomo) this.$matomo.optUserOut();
      }
    },
  },
};
