<template>
  <div class="navigation">
    <div class="navigation_burger">
      <span
        v-if="!isMobileNavOpen"
        class="burger-open-icon"
        @click="isMobileNavOpen = true"
      >
        <theme-icon
          image="burger-open"
          color="currentColor"
          class="navigation__burger-icon"
        />
      </span>
      <span
        v-if="isMobileNavOpen"
        class="burger-close-icon"
        @click="isMobileNavOpen = false"
      >
        <theme-icon
          image="burger-close"
          color="currentColor"
          class="navigation__burger-icon"
        />
      </span>
    </div>
    <ul
      :class="{ 'active' : isMobileNavOpen }"
      class="links-wrapper"
    >
      <li
        v-for="item in navigationLinks"
        :key="item.id"
        class="link"
        @click="isMobileNavOpen = false"
      >
        <router-link
          :to="item.link"
        >
          {{ item.label }}
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>

export default {
  name: 'Navigation',
  props: {
    navigationLinks: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    isMobileNavOpen: false,
  }),
  watch: {
    isMobileNavOpen(val) {
      if (val && window.innerWidth < 1024) {
        this.preventBodyScroll();
      } else {
        this.allowBodyScroll();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/assets/scss/abstracts/_mixins';
@import 'src/assets/scss/abstracts/_variables';

@import 'src/components/common/navigation/navigation';

@import 'src/components/common/navigation/ava';
@import 'src/components/common/navigation/corvette';
</style>
