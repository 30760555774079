<template>
  <div
    v-if="timeToNextPhase"
    class="countdown"
  >
    <span class="countdown__line">
      {{ countdown.days | pad(countdownDaysText ? '' : ':') }}
      <small v-if="countdown.days">{{ countdownDaysText }}</small>
    </span>
    <span class="countdown__line">
      {{ countdown.hours | pad(countdownHoursText ? '' : ':') }}
      <small v-if="countdown.hours">{{ countdownHoursText }}</small>
    </span>
    <span class="countdown__line">
      {{ countdown.minutes | pad(countdownMinutesText ? '' : ':') }}
      <small v-if="countdown.minutes">{{ countdownMinutesText }}</small>
    </span>
    <span class="countdown__line">
      {{ countdown.seconds | pad() }}
      <small v-if="countdown.minutes">{{ countdownSecondsText }}</small>
    </span>
  </div>
</template>

<script>
import { countdownMixin } from '@/helper/phasehelper';

export default {
  name: 'Countdown',

  filters: {
    pad(number, divider = '') {
      return number ? `${number.toString().padStart(2, '0')} ${divider}` : '';
    },
  },

  mixins: [countdownMixin],

  computed: {
    countdown() {
      if (!this.timeToNextPhase || this.timeToNextPhase < 0) return '';

      const dur = this.momentDuration.duration(this.timeToNextPhase);
      return {
        days: dur.days(),
        hours: dur.hours() || '00',
        minutes: dur.minutes() || '00',
        seconds: dur.seconds() || '00',
      };
    },
    countdownDaysText() {
      return this.getText('countdown_days');
    },
    countdownHoursText() {
      return this.getText('countdown_hours');
    },
    countdownMinutesText() {
      return this.getText('countdown_minutes');
    },
    countdownSecondsText() {
      return this.getText('countdown_seconds');
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/abstracts/_mixins';
@import 'src/assets/scss/abstracts/_variables';

@import 'src/components/common/countdown/countdown';

// Themes
@import 'src/components/common/countdown/audi';
</style>
