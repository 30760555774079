import LoginForm from './components/login-form/LoginForm';
import Registration from './pages/registration/Registration';
import RegistrationConfirm from './pages/registration-confirm/RegistrationConfirm';
import RequestPasswordReset from './pages/request-password-reset/RequestPasswordReset';
import PasswordReset from './pages/password-reset/PasswordReset';

export default [
  {
    parent: 'Login',
    route: {
      path: 'userservice',
      component: LoginForm,
      meta: { type: 'landing', hideNavigation: true },
    },
  },
  {
    path: '/register',
    component: Registration,
    name: 'Registration',
    meta: { type: 'landing', hideNavigation: true },
  },
  {
    path: '/registration-confirm',
    component: RegistrationConfirm,
    name: 'RegistrationConfirm',
    meta: { type: 'landing', hideNavigation: true },
  },
  {
    path: '/request-password-reset',
    component: RequestPasswordReset,
    name: 'RequestPasswordReset',
    meta: { type: 'landing', hideNavigation: true },
  },
  {
    path: '/password-reset',
    component: PasswordReset,
    name: 'PasswordReset',
    meta: { type: 'landing', hideNavigation: true },
  },
];
