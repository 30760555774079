import _ from 'lodash/fp';
import moment from 'moment-timezone';

export const PHASE_CONSTANTS = {
  NAME: 'phases',
  STATE: {
    CURRENT_PHASE: 'currentPhase',
    NEXT_PHASE: 'nextPhase',
  },
  MUTATIONS: {
    SET_NEXT_PHASE: 'setNextPhase',
    SET_CURRENT_PHASE: 'setCurrentPhase',
  },
  GETTERS: {
    LANDING_MODE: 'landingMode',
    EVENT_PHASES: 'eventPhases',
  },
  ACTIONS: {
    CHANGE_CURRENT_PHASE: 'changeCurrentPhase',
    UPDATE_CURRENT_PHASE: 'updateCurrentPhase',
  },
};

export default {
  namespaced: true,

  state: {
    [PHASE_CONSTANTS.STATE.CURRENT_PHASE]: null,
    [PHASE_CONSTANTS.STATE.NEXT_PHASE]: null,
  },

  mutations: {
    [PHASE_CONSTANTS.MUTATIONS.SET_NEXT_PHASE]:
      (state, nextPhase) => state.nextPhase = nextPhase,
    [PHASE_CONSTANTS.MUTATIONS.SET_CURRENT_PHASE]:
      (state, currentPhase) => state.currentPhase = currentPhase,
  },

  getters: {
    [PHASE_CONSTANTS.GETTERS.LANDING_MODE]:
      ({ currentPhase }) => (currentPhase || {}).landing_mode,
    [PHASE_CONSTANTS.GETTERS.EVENT_PHASES]:
      (state, getters, rootState) => _.sortBy(item => item.start)(rootState.cms.phases),
  },

  actions: {
    // Changes the current phase if not in debug mode
    [PHASE_CONSTANTS.ACTIONS.CHANGE_CURRENT_PHASE]:
      ({ state, commit, rootState }, currentPhase) => {
       if (rootState.debug && state.currentPhase) return;
        commit(PHASE_CONSTANTS.MUTATIONS.SET_CURRENT_PHASE, currentPhase);
      },

    // Updates the current phase and the next phase based on the current time
    [PHASE_CONSTANTS.ACTIONS.UPDATE_CURRENT_PHASE]: ({
      state, commit, dispatch, getters,
    }) => {
      const nextPhase = getters[PHASE_CONSTANTS.GETTERS.EVENT_PHASES].slice().reverse()
          .reduce((p, next) => moment()
          .isBefore(moment(next.start)) ? next : p, null);

      commit(PHASE_CONSTANTS.MUTATIONS.SET_NEXT_PHASE, nextPhase);

      // do not update current phase if in debug mode
      if (state.debug && state.currentPhase) return;

      const currentPhase = getters[PHASE_CONSTANTS.GETTERS.EVENT_PHASES]
        .reduce((p, next) => moment().isAfter(moment(next.start))
          ? next
          : p, null);

      dispatch(PHASE_CONSTANTS.ACTIONS.CHANGE_CURRENT_PHASE, currentPhase);
    },
  },
};
