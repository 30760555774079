import ModalPIP from '@/components/common/modalpip/ModalPIP';

const modalPIP = {

  install(Vue) {
    this.EventBus = new Vue();

    Vue.component('ModalPip', ModalPIP);

    Vue.prototype.$modalPIP = {
      show: params => {
        modalPIP.EventBus.$emit('show', params);
      },
    };
  },
};

export default modalPIP;
