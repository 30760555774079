<template>
  <div
    v-if="browserWarningText || browserWarningHeadline"
    class="browser-warning"
  >
    <div class="browser-warning__container">
      <h4>{{ browserWarningHeadline }}</h4>
      <div> {{ browserWarningText }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BrowserWarning',
  computed: {
    browserWarningText() {
      return this.getText('browser_warning_text');
    },
    browserWarningHeadline() {
      return this.getText('browser_warning_headline');
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/abstracts/_mixins';
@import 'src/assets/scss/abstracts/_variables';

@import 'src/components/common/browserwarning/browserwarning';

// Themes
@import 'src/components/common/browserwarning/bmw-car';
</style>
