import store from '@/store';
import { LandingModes, LandingRoutes } from '@/util/landingModes';

// constants
import { PHASE_CONSTANTS } from '@/store/phasesModule';

export default (async (to, from, next) => {
  // load phases is landingMode is not set
  if (!store.getters[`${PHASE_CONSTANTS.NAME}/${PHASE_CONSTANTS.GETTERS.LANDING_MODE}`]) {
    await store.dispatch('cms/getItems', { tableName: 'phases', params: { fields: '*.*' } });
  }

  const landingMode = store.getters[`${PHASE_CONSTANTS.NAME}/${PHASE_CONSTANTS.GETTERS.LANDING_MODE}`];
  const open = landingMode === LandingModes.LANDING_MODE_NONE;
  const authRequired = to.matched.some(route => route.meta.auth);
  const landingRoute = LandingRoutes[landingMode];

  // no auth mode / no auth route
  if (open || !authRequired) {
    next();
    return;
  }

  // auth route: check if user is authenticated
  const authenticated = store.getters['auth/authenticated'];
  if (authRequired && !authenticated) {
    const goToRoute = { path: landingRoute };
    if (to) goToRoute.query = { returnTo: to.fullPath };
    next(goToRoute);
  } else {
    next();
  }
});
