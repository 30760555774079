<template>
  <div class="debug">
    <div>
      cms:
      <strong>{{ cmsBasePath }}</strong>
    </div>
    <div>
      auth mode:
      <strong>{{ loginMode }}</strong>
    </div>
    <div>
      auth user:
      <strong>{{ user?.name }}</strong>
    </div>
    <div>
      cms user:
      <strong>{{ cmsGroup }}</strong>
    </div>

    <div class="debug__block">
      <select
        v-model="selectedEventPhase"
        class="debug__select"
      >
        <option value="">
          switch event phase 🚀
        </option>
        <option
          v-for="phase in eventPhases"
          :key="phase.name"
          :value="phase"
        >
          {{ phase.name }}
        </option>
      </select>
    </div>

    <div class="debug__block">
      <select
        v-model="selectedTheme"
        class="debug__select"
      >
        <option value="">
          switch theme 🚀
        </option>
        <option
          v-for="theme in themes"
          :key="theme.name"
        >
          {{ theme.name }}
        </option>
      </select>
    </div>

    <div class="debug__block">
      <select
        v-model="selectedHomeMode"
        class="debug__select"
      >
        <option value="">
          switch homeMode 🚀
        </option>
        <option>delegate</option>
        <option>press</option>
        <option>hybrid</option>
      </select>
    </div>

    <div>
      <input
        v-model="selectedFullPagesHomeMode"
        type="checkbox"
        @change="switchFullPagesHomeMode"
      >
      &nbsp;show full pages (home)
    </div>

    <a @click="toggleRoutes">
      &gt;&gt;
      <strong>{{ showRoutes ? 'hide' : 'show' }} routes</strong>
      &lt;&lt;
    </a>

    <app-button
      label="close & reset"
      primary
      :callback="() => closeAndReset()"
    />

    <div v-if="showRoutes">
      <ul class="row">
        <li
          v-for="route in $router.options.routes"
          :key="route.path"
          class="columns small-12 medium-4"
        >
          <router-link :to="{ name: route.name }">
            <b>{{ route.name }}</b> - {{ route.path }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

// constants
import { PHASE_CONSTANTS } from '@/store/phasesModule';

// mixins
import { phaseMixin } from '@/helper/phasehelper';

export default {
  name: 'Debug',
  mixins: [phaseMixin],

  data() {
    return {
      selectedFullPagesHomeMode: '',
      showRoutes: false,
    };
  },

  computed: {
    ...mapState({
      fullPagesHomeMode: state => state.fullPagesHomeMode,
      customerName: state => state.customer.name,
      homeMode: state => state.homeMode,
    }),

    ...mapState('auth', ['user', 'loginMode']),
    ...mapState(PHASE_CONSTANTS.NAME, {
      currentPhase: state => state[PHASE_CONSTANTS.STATE.CURRENT_PHASE],
    }),

    ...mapState('cms', {
      themes: state => state.themes,
      phases: state => state.phases,
    }),

    selectedTheme: {
      get() { return this.customerName; },
      set(val) { return this.$store.commit('setCustomerDebug', val); },
    },

    selectedHomeMode: {
      get() { return this.homeMode; },
      set(val) { return this.$store.commit('setHomeModeDebug', val); },
    },

    selectedEventPhase: {
      get() { return this.currentPhase; },
      set(val) { return this.setCurrentPhase(val); },
    },
  },

  mounted() {
    this.$store.dispatch('cms/getItems', { tableName: 'themes' });
  },

  methods: {
    ...mapMutations(['setDebug']),
    ...mapMutations(PHASE_CONSTANTS.NAME, {
      setCurrentPhase: PHASE_CONSTANTS.MUTATIONS.SET_CURRENT_PHASE,
    }),

    switchFullPagesHomeMode() {
      this.$store.commit('setFullPagesHomeMode', this.selectedFullPagesHomeMode);
    },
    toggleRoutes() {
      this.showRoutes = !this.showRoutes;
    },
    closeAndReset() {
      this.setDebug(false);
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, debug: undefined },
      });
      window.location.reload();
    },
  },
};
</script>

<style lang="scss">
@import 'src/components/common/debug/debug';
</style>
