<template>
  <div class="landinglayout__outer">
    <div
      :style="getBackgroundStyle()"
      class="page landinglayout content-wrapper"
    >
      <video
        v-if="isVideoBackground"
        :src="videoBackground"
        autoplay
        muted
        loop
        playsinline
        class="landinglayout__video-background"
      />

      <div class="landinglayout__overlay" />

      <div class="landinglayout__inner">
        <div class="landinglayout__welcome landinglayout__background-image">
          <div class="landinglayout__welcome-container">
            <h1
              v-if="heading"
              v-sane-html="heading"
              class="landinglayout__welcome--header"
            />

            <h2
              v-if="copy"
              v-sane-html="copy"
              class="landinglayout__welcome--copy"
            />
          </div>
        </div>

        <div class="landinglayout__wrapper">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import skinner from '@/components/mixins/skinner';

export default {
  name: 'LandingLayout',

  mixins: [skinner],

  props: {
    heading: {
      type: String,
      default: '',
    },
    copy: {
      type: String,
      default: '',
    },
  },

  computed: {

    loginBackground() {
      return this.getProp('login', 'image', {});
    },

    videoBackground() {
      return this.cmsFile(this.loginBackground.id);
    },

    isVideoBackground() {
      if (!this.loginBackground) return false;
      return (this.loginBackground.type || '').includes('video');
    },
  },

  methods: {
    getBackgroundStyle() {
      const image = this.loginBackground;
      if (this.isVideoBackground) return '';
      const bg = `url('${image ? this.responsiveCmsImage(image.id, 1440, 920) : ''}')`;
      return this.getSkin === 'bmw-mini'
       ? { backgroundImage: bg } : `--background: ${bg}`;
    },
  },
};
</script>

<style lang="scss">
@import '~foundation-sites/scss/foundation';
@import 'src/assets/scss/abstracts/variables';
@import 'src/assets/scss/abstracts/mixins';

@import 'src/layouts/landinglayout/landinglayout';

// Themes
@import 'src/layouts/landinglayout/audi';
@import 'src/layouts/landinglayout/ava';
@import 'src/layouts/landinglayout/bmw-car';
@import 'src/layouts/landinglayout/bmw-group';
@import 'src/layouts/landinglayout/corporate';
@import 'src/layouts/landinglayout/corvette';
@import 'src/layouts/landinglayout/bmw-i';
@import 'src/layouts/landinglayout/bmw-m';
@import 'src/layouts/landinglayout/bmw-mini';
@import 'src/layouts/landinglayout/bmw-motorrad';
@import 'src/layouts/landinglayout/hyu-standard';
@import 'src/layouts/landinglayout/iqos';
</style>
