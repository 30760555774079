// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'intersection-observer';
import 'babel-polyfill';
import 'leaflet/dist/leaflet.css';

import Vue from 'vue';
import VueResource from 'vue-resource';
import VueScrollTo from 'vue-scrollto';
import VueClazyLoad from 'vue-clazy-load';
import VueYoutube from 'vue-youtube';
import progress from 'nprogress';
import VModal from 'vue-js-modal';
import VMediaQuery from 'v-media-query';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import moment from 'moment-timezone';
import DOMPurify from 'dompurify';

import Button from '@/components/common/button/Button';
import DescriptionList from '@/components/common/descriptionlist/DescriptionList';
import Input from '@/components/common/input/Input';
import Textarea from '@/components/common/textarea/Textarea';
import PageHeaderText from '@/components/common/pageheadertext/PageHeaderText';
import Subpage from '@/components/common/subpage/Subpage';
import ThemeIcon from '@/components/common/themeicon/ThemeIcon';

import App from '@/App';
import router from '@/router';
import store from '@/store';
import '@/directives/main';

import responsiveCmsImage from '@/components/mixins/responsiveCmsImage';
import tracking from '@/components/mixins/tracking';
import global from '@/components/mixins/global';
import auth from '@/helper/auth';

// Import global plugins
import DetectDevice from '@/plugins/device';
import modalPIP from '@/plugins/modalPIP';

import { registerModules } from '@/util/registerModules';
import LoginModes from '@/util/loginModes';

import oidcModule from '@/modules/auth/oidc';
import userserviceModule from '@/modules/auth/userservice';
import defaultModule from '@/modules/auth/default';

import simplePageModule from '@/modules/simple-page';

const { VUE_APP_LOGIN_TYPE } = process.env;

// Switch auth module by implementing
// the required actions in modules/auth/MODULE
switch (VUE_APP_LOGIN_TYPE) {
  case LoginModes.OIDC:
    registerModules({ [LoginModes.OIDC]: oidcModule }, 'auth'); break;
  case LoginModes.USERSERVICE:
    registerModules({ [LoginModes.USERSERVICE]: userserviceModule }, 'auth'); break;
  case LoginModes.DEFAULT:
    registerModules({ [LoginModes.DEFAULT]: defaultModule }, 'auth'); break;
  default:
    break;
}

// SimplePage Module needs to be imported after other modules
// as it registers catch-all route
registerModules({ 'simple-page': simplePageModule });

// Setting default timezone for Server Dates (as directus stores dates without utc offset)
// use moment.local() for users browser-times
// overwritten by cms setting (basics.timezone)
if (process.env.VUE_APP_DIRECTUS_TIMEZONE) {
  moment.tz.setDefault(process.env.VUE_APP_DIRECTUS_TIMEZONE);
}

Vue.use(VueResource);
Vue.use(VueScrollTo);
Vue.use(VueClazyLoad);
Vue.use(VueYoutube);
Vue.use(VModal);
Vue.use(VMediaQuery);
Vue.use(DetectDevice);
Vue.use(modalPIP);

Vue.config.productionTip = false;

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new VueIntegration({
      Vue,
      attachProps: true,
      tracing: true,
    }),
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 1.0,
});

Vue.mixin(tracking);
Vue.mixin(responsiveCmsImage);
Vue.mixin(global);

router.beforeEach(auth);
router.afterEach(() => progress.done());

Vue.component('ThemeIcon', ThemeIcon);
Vue.component('Subpage', Subpage);
Vue.component('AppButton', Button);
Vue.component('AppInput', Input);
Vue.component('AppTextarea', Textarea);
Vue.component('DescriptionList', DescriptionList);
Vue.component('PageHeaderText', PageHeaderText);

Vue.directive('sane-html', (el, binding) => {
  el.innerHTML = DOMPurify.sanitize(binding.value, { ADD_TAGS: ['iframe'] });
});

const init = () => {
  new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    render: h => h(App),
  }).$mount('#app');
};

/**
 * Inits the active auth mechanism
 * TBD: prevent app start if initialization fails
 */
store.dispatch('auth/init')
  // .then(() => init())
  .catch((error) => console.error(error))
  .finally(() => init());
