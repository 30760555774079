<template>
  <landing-layout
    :heading="getText('password_reset_welcome', textCollection)"
    :copy="getText('password_reset_copy', textCollection)"
    class="password-reset"
  >
    <div class="landinglayout__container">
      <div class="landinglayout__form">
        <div class="landinglayout__errors">
          <div
            v-if="serverSideError"
            class="landinglayout__error-message"
          >
            {{ serverSideError }}
          </div>
          <div
            v-for="(error, idx) in Object.values(validationErrors).filter(e => !!e && e.trim())"
            :key="idx"
            class="landinglayout__error-message"
          >
            <span v-sane-html="error" />
          </div>
        </div>

        <div
          v-if="passwordResetSuccess && !serverSideError"
          class="landinglayout__success-message"
        >
          {{ passwordResetSuccessMessage }}
        </div>

        <form
          v-show="!passwordResetSuccess"
          @submit.prevent="resetPassword"
        >
          <app-input
            ref="email"
            v-model="email"
            type="text"
            autocorrect="off"
            autocomplete="email"
            class="password-reset__username"
            disabled
          />

          <app-input
            ref="password"
            v-model="password"
            :input-class="['landinglayout__input', { 'error': validationErrors.password } ]"
            :label="getText('password_reset_placeholder', textCollection)"
            :placeholder="getText('password_reset_placeholder', textCollection)"
            type="password"
            autocorrect="off"
            autocapitalize="none"
            autocomplete="new-password"
            @input="validationErrors.password = null"
            @keyup.enter="resetPassword"
          />

          <template v-if="passwordRules.length">
            <span class="landinglayout__note">
              {{ getText('password_reset_password_note', textCollection) }}
              {{ passwordRules }}
            </span>
          </template>

          <app-input
            ref="passwordConfirmation"
            v-model="passwordConfirmation"
            :input-class="[
              'landinglayout__input', { 'error': validationErrors.passwordConfirmation }
            ]"
            :label="getText('password_reset_confirmation_placeholder', textCollection)"
            :placeholder="getText('password_reset_confirmation_placeholder', textCollection)"
            type="password"
            autocorrect="off"
            autocapitalize="none"
            @input="validationErrors.passwordConfirmation = null"
            @keyup.enter="resetPassword"
          />

          <app-button
            :disabled="loading"
            :label="getText('password_reset_submit_label', textCollection)"
            class="landinglayout__button"
          />
        </form>

        <div v-show="passwordResetSuccess">
          <app-button
            :disabled="loading"
            :callback="login"
            :label="getText('password_reset_enter_now_button_label', textCollection)"
            class="landinglayout__button"
            primary
          />
        </div>
      </div>
    </div>
  </landing-layout>
</template>

<script>
// Constants
import { ApiErrors, ConfigurationErrors } from '@/util/errors';

import LandingLayout from '@/layouts/landinglayout/LandingLayout';

import passwordValidator from '@/helper/passwordValidator';

export default {
  name: 'PasswortReset',

  components: {
    LandingLayout,
  },

  mixins: [passwordValidator],

  data: () => ({
    email: '',
    verificationCode: false,
    resetCode: false,
    loading: false,
    loginErrorMessage: '',
    password: '',
    passwordConfirmation: '',
    passwordResetError: '',
    passwordResetSuccess: false,
    passwordResetSuccessMessage: '',
    serverSideError: false,
    validationErrors: {},
    textCollection: 'login',
  }),

  // resetCode -> reset the password via shared link by event owner, first password
  // verificationCode -> self requesting, forgot your password
  mounted() {
    if (!this.$route.query.email) this.logout();
    else if (!this.$route.query.verificationCode && !this.$route.query.resetCode) this.logout();
    this.email = this.$route.query.email;
    this.verificationCode = this.$route.query.verificationCode;
    this.resetCode = this.$route.query.resetCode;
  },

  methods: {
    validate() {
      this.validationErrors = {};
      this.serverSideError = false;

      if (!this.password) {
        this.validationErrors.password = this.getText('password_reset_password_missing_error_message', this.textCollection);
      } else {
        const invalidRules = this.passwordRulesNotMatched(this.password);
        if (invalidRules) {
          this.validationErrors.password = this.getText('password_reset_password_too_weak_error_message', this.textCollection);
          this.validationErrors.password += `<ul><li>${ invalidRules.join('</li><li>') }</li></ul>`;
        }
      }

      if (!this.passwordConfirmation) {
        this.validationErrors.passwordConfirmation = this.getText('password_reset_password_confirmation_missing_error_message', this.textCollection);
      } else if (this.password && this.password !== this.passwordConfirmation) {
        this.validationErrors.passwordConfirmation = this.getText('password_reset_passwords_dont_match_error_message', this.textCollection) || ' ';
      }

      return !Object.values(this.validationErrors).length;
    },

    async resetPassword() {
      if (!this.validate()) return;
      this.$refs.password.$refs.input.blur();
      this.$refs.passwordConfirmation.$refs.input.blur();
      this.loading = true;

      try {
        await this.$store.dispatch(
          'auth/userservice/resetPassword',
          {
            email: this.email,
            password: this.password,
            verificationCode: this.verificationCode,
            resetCode: this.resetCode,
          },
        );
        this.passwordResetSuccessMessage = this.getText('password_reset_success_message', this.textCollection);
        this.passwordResetSuccess = true;
      } catch (err) {
        switch (err.message) {
          case ApiErrors.USER_NOT_FOUND:
            this.serverSideError = this.getText('password_reset_user_not_found', this.textCollection);
            break;
          case ApiErrors.CODE_INVALID:
            this.serverSideError = this.getText('password_reset_reset_code_invalid', this.textCollection);
            break;
          case ApiErrors.PASSWORD_ATTEMPTS_EXCEEDED:
            this.serverSideError = this.getText('password_reset_attempts_exceeded', this.textCollection);
            break;
          case ApiErrors.CODE_ALREADY_USED:
            this.serverSideError = this.getText('password_reset_reset_code_already_used', this.textCollection);
            break;
          case ConfigurationErrors.OPERATION_NOT_SUPPORTED:
            this.serverSideError = this.getText('config_not_supported');
            break;
          default: this.serverSideError = this.getText('password_reset_generic_error', this.textCollection);
        }
      } finally {
        this.loading = false;
      }

      this.$tracking.event({
        eventCategory: 'User',
        eventAction: this.passwordResetSuccess ? 'PasswordReset' : 'PasswordResetFailed',
      });
    },

    async login() {
      this.loading = true;
      this.serverSideError = false;
      const credentials = {
        email: this.email,
        password: this.password,
      };

      try {
        await this.$store.dispatch('auth/userservice/login', { credentials });
        this.$router.push({ name: 'Home' });
      } catch (err) {
        this.serverSideError = err.message === ApiErrors.CMS_LOGIN_FAILED
          ? this.getText('login_error_cms', this.textCollection) : this.getText('login_error_message', this.textCollection);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '~foundation-sites/scss/foundation';
@import 'src/assets/scss/abstracts/variables';
@import 'src/assets/scss/abstracts/mixins';

@import 'password-reset';
</style>
