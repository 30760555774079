<template>
  <div ref="declaration" />
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CookiebotDeclaration',

  computed: {
    ...mapState('cms', {
      adminBasics: state => state.admin_basics,
    }),

    cookiebotID() {
      return (this.adminBasics || {}).cookiebot_id;
    },
  },

  mounted() {
    if (!this.cookiebotID || !process.env.VUE_APP_COOKIEBOT_URL || !this.$refs.declaration) return;
    this.setCookiebot();
  },

  methods: {
    setCookiebot() {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `${process.env.VUE_APP_COOKIEBOT_URL}/${this.cookiebotID}/cd.js`;
      script.setAttribute('id', 'CookiebotDeclaration');
      this.$refs.declaration.appendChild(script);
    },
  },
};
</script>
