import { mapState } from 'vuex';

export default {
  name: 'Skinner',
  computed: {
    ...mapState(['customer']),
    getSkin() {
      return this.skinner(this.customer.skin);
    },
    getThemeLogos() {
      return this.customer.logos || {};
    },
    getSkinClass() {
      return `${this.getSkin} ${this.customer.anon ? 'anon' : ''}`;
    },
    getTheme() {
      return this.themer(this.getSkin);
    },
    getThemeData() {
      return this.themeData(this.cmsSkinner);
    },
  },
  methods: {
    // Return the selected skin
    skinner(name) {
      return name || null;
    },

    // Return selected theme
    themer(name) {
      let theme = '';
      if (name) {
        theme = `--${name}`;
      }
      return theme;
    },

    // return theme data / skin name
    themeData() {
      let skinName = this.getSkin;

      if (skinName === null) {
        // eslint-disable-next-line
        console.error('Warning', 'No theme set!');
      } else {
        skinName = skinName.replace('bmw-', '');
      }

      return skinName;
    },
  },
};
