import Vue from 'vue';
import store from '@/store';

const userReplacer = (match, p) => {
  const userData = store.state.auth.user || {};
  return userData[p] || '';
};

Vue.directive('custom-html', {
  bind: (el, binding) => {
    const { value } = binding;
    return el.innerHTML = value.replace(/\$\{(\w+?)\}/g, userReplacer);
  },
  update: (el, binding) => {
    const { value } = binding;
    return el.innerHTML = value.replace(/\$\{(\w+?)\}/g, userReplacer);
  },
});
