import { mapState, mapGetters, mapActions } from 'vuex';

// constants
import { PHASE_CONSTANTS } from '@/store/phasesModule';

// Adds filter by phases to getItems action api call by vuex-ir-directus
export const phaseFilter = options => store => {
  store.subscribeAction(action => {
    if (action.type === 'cms/getItems') {
      if (!options.paths.includes(action.payload.tableName)) return;
      if (!action.payload.filters) action.payload.filters = {};
      action.payload.filters.phases = {
        phases_id: {
          name: {
            _in: store.state[PHASE_CONSTANTS.NAME][PHASE_CONSTANTS.STATE.CURRENT_PHASE]?.name ?? 'none',
          },
        },
      };
    }
  });
};

// Mixin used by components displaying phase Countdown
export const countdownMixin = {

  data() {
    return {
      countdownInterval: null,
      timeToNextPhase: 0,
    };
  },

  watch: {
    currentPhase: {
      immediate: true,
      handler(phase) {
        this.removeCountdownTimer();
        if (
            phase && phase.show_countdown
            && this.countdownAvailable
            && !this.countdownTimerActive
          ) {
          this.startCountdownTimer();
        }
      },
    },
  },

  destroyed() {
    this.removeCountdownTimer();
  },

  computed: {
    ...mapState(PHASE_CONSTANTS.NAME, {
      currentPhase: state => state[PHASE_CONSTANTS.STATE.CURRENT_PHASE],
      nextPhase: state => state[PHASE_CONSTANTS.STATE.NEXT_PHASE],
    }),

    countdownTimerActive() {
      return !!this.countdownInterval;
    },
    countdownAvailable() {
      return !!(this.nextPhase || {}).start;
    },
  },

  methods: {
    // sets interval to the timeToNextPhase value to be used as countdown (1s)
    startCountdownTimer() {
      this.removeCountdownTimer();
      if (!this.nextPhase) return;
      this.countdownInterval = setInterval(() => {
        this.timeToNextPhase = this
          .moment(this.nextPhase.start)
          .diff(this.moment());

        if (!this.nextPhase || this.timeToNextPhase < 100) {
          this.removeCountdownTimer();
        }
      }, 1000);
    },

    removeCountdownTimer() {
      clearInterval(this.countdownInterval);
      this.countdownInterval = null;
      this.timeToNextPhase = 0;
    },
  },
};

// Mixin used by Components displaying Phase dependent Content
export const phaseMixin = {

  watch: {
    currentPhase: {
      handler() {
        this.updateData();
      },
    },
  },

  computed: {
    ...mapGetters('cms', ['getProp']),
    ...mapGetters(PHASE_CONSTANTS.NAME, {
      eventPhases: PHASE_CONSTANTS.GETTERS.EVENT_PHASES,
    }),
    ...mapState(PHASE_CONSTANTS.NAME, {
      currentPhase: state => state[PHASE_CONSTANTS.STATE.CURRENT_PHASE],
      nextPhase: state => state[PHASE_CONSTANTS.STATE.NEXT_PHASE],
    }),
  },

  methods: {
    ...mapActions(PHASE_CONSTANTS.NAME, {
      updateCurrentPhase: PHASE_CONSTANTS.ACTIONS.UPDATE_CURRENT_PHASE,
    }),
    updateData() {}, // to be implemented by components with phase dependent content
  },
};

// Mixin used in App Component to trigger phase Update
export const phaseUpdater = {

  data() {
    return {
      updateTimer: null,
    };
  },

  computed: {
    ...mapGetters('cms', ['basics']),
    ...mapState('cms', ['phases']),
    ...mapGetters(PHASE_CONSTANTS.NAME, {
      eventPhases: PHASE_CONSTANTS.GETTERS.EVENT_PHASES,
    }),
    ...mapState(PHASE_CONSTANTS.NAME, {
      currentPhase: state => state[PHASE_CONSTANTS.STATE.CURRENT_PHASE],
      nextPhase: state => state[PHASE_CONSTANTS.STATE.NEXT_PHASE],
    }),
    ...mapState(['debug']),
  },

  watch: {
    // update current phase if the timezone changes
    basics: {
      immediate: true,
      handler(val, old) {
        const timezoneNew = val ? (val[0] || {}).timezone : null;
        const timezoneOld = old ? (old[0] || {}).timezone : null;
        if (timezoneNew === timezoneOld) return;
        setTimeout(() => this.updateCurrentPhase(), 200);
      },
    },

    // update current phase if eventPhases change
    eventPhases: {
      immediate: true,
      handler() {
        this.updateCurrentPhase();
      },
    },

    nextPhase: {
      immediate: true,
      handler(val, old) {
        clearInterval(this.countdownInterval);
        if (val?.id !== old?.id && val?.start) {
          this.startUpdateTimer();
        }
      },
    },
  },

  methods: {
    ...mapActions(PHASE_CONSTANTS.NAME, {
      updateCurrentPhase: PHASE_CONSTANTS.ACTIONS.UPDATE_CURRENT_PHASE,
    }),

    // sets timer to update the current phase internally on new phase starttime
    startUpdateTimer() {
      if (!this.nextPhase) return;
      const updateTime = this
        .moment(this.nextPhase.start)
        .diff(this.moment());

      // if updateTime longer than one week, do not set timer
      if (updateTime > 604800000) return;

      this.updateTimer = setTimeout(() => {
        clearTimeout(this.updateTimer);
        // do not update if in debug mode
        if (this.debug && this.currentPhase) return;
        this.updateCurrentPhase();
      }, updateTime);
    },
  },
};
