import store from '@/store';

const log = (txt, params) => store.state.debug && console.log(`DEFAULT Auth Module: ${txt}`, params);

export default {
  namespaced: true,

  actions: {
    /**
     * Calls auth module directus login
     *
     * @param { string } group url to return to after login
     *
     * @returns {Promise<void>}
     */
    async login({ dispatch }, { group }) {
      log('login');
      return dispatch('auth/directusLogin', { group }, { root: true });
    },

    /**
     * Calls ir-vuex-directus module me
     * if user cms is authenticated
     *
     * @returns {Promise<void>}
     */
    async user({ dispatch, rootState }) {
      log('get user');
      if (!rootState.cms.authenticated) return true;
      return dispatch('cms/me', {}, { root: true });
    },
  },
};
