import axios from 'axios';
import store from '@/store';

import { tokenAuth } from '@/helper/authHelper';

const {
  VUE_APP_USER_SERVICE_API_URL,
  // VUE_APP_API_URL,
  VUE_APP_USER_SERVICE_SCOPE_ID,
} = process.env;

const request = axios.create({
  baseURL: VUE_APP_USER_SERVICE_API_URL,
});

const log = (txt, params) => store.state.debug && console.log(`USERSERVICE Service: ${txt}`, params);

request.interceptors.response.use(response => response, async error => {
  const status = error.response ? error.response.status : null;
  const originalRequest = error.config;
  const isAuthRequest = !!(originalRequest.headers || {}).Authorization;
  if (status === 401 && originalRequest.url.includes('refresh')) {
    store.dispatch('auth/logout', {}, { root: true });
  } else if (status === 401 && !originalRequest.isRetry && isAuthRequest) {
    originalRequest.isRetry = true;
    await store.dispatch('auth/userservice/refresh');
    originalRequest.headers = tokenAuth(store.state.auth.userservice.token);
    return request(originalRequest);
  }

  return Promise.reject(error);
});

export default {
  /**
   * Fetches user data
   *
   * @param { string } token
   *
   * @returns {Promise<void>}
   */
  getUser({ token }) {
    log('getUser');
    const headers = token ? tokenAuth(token) : '';
    return request.get('/users/me', { headers, params: { scopeId: VUE_APP_USER_SERVICE_SCOPE_ID } });
  },

  /**
   * Calls api login url with redirect param to the current app
   *
   * @param { string } returnToRoute url to return to after login
   * @param {{ email:string, password:string }} credentials email, password object
   *
   * @returns {Promise<void>}
   */
  login({ returnToRoute, credentials }) {
    log('login', returnToRoute, credentials);
    return request.post('/users/login', {
      email: credentials.email,
      password: credentials.password,
      scopeId: VUE_APP_USER_SERVICE_SCOPE_ID,
    });
  },

  /**
   * Calls api login url with redirect param to the current app
   *
   * @param { string } returnToRoute url to return to after login
   *
   * @returns {Promise<void>}
   */
  logout({ returnToRoute }) {
    log('logout', returnToRoute);
  },

  /**
   * Calls api token refresh
   *
   * @param { string } refreshToken
   *
   * @returns {Promise<void>}
   */
  async refreshToken({ refreshToken }) {
    return request.post('/users/refresh', { refreshToken });
  },

  /**
   * Calls api password policies
   *
   * @returns {Promise<void>}
   */
  async passwordPolicies() {
    return request.get('/admins/password-policies');
  },

  /**
   * Calls api signup
   *
   * @param {*} formData
   *
   * @returns {Promise<void>}
   */
  async register({ formData }) {
    return request.post('/users/signup', { ...formData, scopeId: VUE_APP_USER_SERVICE_SCOPE_ID });
  },

  /**
   * Calls api confirm registration
   *
   * @param { string } email
   * @param { string } verificationCode
   *
   * @returns {Promise<void>}
   */
  async confirmRegistration({ email, verificationCode }) {
    return request.post('/users/confirm-registration', { email, verificationCode });
  },

  /**
   * Calls api set password
   * in case of self requesting, forgot your password
   *
   * @param { string } email
   * @param { string } password
   * @param { string } verificationCode
   *
   * @returns {Promise<void>}
   */
  async resetPassword({ email, password, verificationCode }) {
    return request.post('/users/confirm-password', { email, password, verificationCode });
  },

  /**
   * Calls api reset password trigger
   *
   * @param { string } email
   *
   * @returns {Promise<void>}
   */
  async requestPasswordReset({ email }) {
    return request.post('/users/reset-password', { email, scopeId: VUE_APP_USER_SERVICE_SCOPE_ID });
  },

  /**
   * Calls api set password
   * in case of password reset via shared link by event owner, first password
   *
   * @param { string } email
   * @param { string } password
   * @param { string } resetCode
   *
   * @returns {Promise<void>}
   */
  async setPassword({ email, password, resetCode }) {
    return request.post('/users/set-password', { email, password, resetCode });
  },
};
