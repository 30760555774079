<template>
  <cookie-bar
    ref="cookieLaw"
    :button-decline="true"
    theme="hub"
    button-class="cookie__button app-button app-button--primary"
    button-text="OK"
    button-decline-text="Cancel"
    button-decline-class="cookie__button cookie-cancel-button app-button app-button--secondary"
    @accept="cookiesAccepted()"
  >
    <div slot="message">
      <h4 class="cookie-header">
        {{ getText('cookiebar_header') }}
      </h4>
      {{ getText('cookiebar_content') }}
    </div>
  </cookie-bar>
</template>

<script>
import { mapState } from 'vuex';
import CookieBar from 'vue-cookie-law';

export default {
  name: 'AppCookieBar',
  components: {
    CookieBar,
  },
  computed: {
    ...mapState('cms', {
      adminBasics: state => state.admin_basics,
    }),
  },
  methods: {
    cookiesAccepted() {
      this.setTracking(this.adminBasics);
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/abstracts/_mixins';
@import 'src/assets/scss/abstracts/_variables';

@import 'src/components/common/cookiebar/cookiebar';

// Themes
@import 'src/components/common/cookiebar/audi';
@import 'src/components/common/cookiebar/ava';
@import 'src/components/common/cookiebar/bmw-car';
@import 'src/components/common/cookiebar/bmw-group';
@import 'src/components/common/cookiebar/bmw-mini';
@import 'src/components/common/cookiebar/bmw-motorrad';
@import 'src/components/common/cookiebar/iqos';
@import 'src/components/common/cookiebar/vw';
</style>
