<template>
  <div />
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CookiebotBanner',

  computed: {
    ...mapState('cms', {
      adminBasics: state => state.admin_basics,
    }),

    cookiebotID() {
      return (this.adminBasics || {}).cookiebot_id;
    },
  },

  mounted() {
    if (this.cookiebotID && process.env.VUE_APP_COOKIEBOT_URL) this.setCookiebot();
  },

  methods: {
    setCookiebot() {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `${process.env.VUE_APP_COOKIEBOT_URL}/uc.js`;
      script.setAttribute('id', 'Cookiebot');
      script.setAttribute('data-culture', 'en');
      script.setAttribute('data-cbid', this.cookiebotID);
      script.onload = () => {
        window.addEventListener('CookiebotOnAccept', () => {
          if (window.CookieConsent.consent.statistics) {
            this.setTracking(this.adminBasics);
          }
        }, false);
      };
      document.head.appendChild(script);
    },
  },
};
</script>
